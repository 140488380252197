import {
  Button,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import AvatarEditor from 'react-avatar-editor';
import User from '@/client/services/api/User';
import axios from 'axios';
import { useRef } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';
import AnalyzeService from '@/client/services/api/AnalyzeService';

type EditorProps = {
  userId?: string;
  avatar: any;
  setAvatar: (avatar: any) => void;
  setAvatarUploading: (uploading: boolean) => void;
};

export default function Editor({ userId, avatar, setAvatar, setAvatarUploading }: EditorProps) {
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  let editor: any = useRef(null);

  const updateUserAvatar = useMutation({
    mutationFn: (url: string) => User.updateAvatar(userId!, url),
    onSuccess: (image) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setAvatarUploading(false);
      AnalyzeService.recordAvatars(image);
      setAvatar({ ...avatar, editorModalOpen: false, img: '', scale: 1.5, croppedImg: null });
      setToast({
        show: true,
        status: 'success',
        title: t('modules.notifications.profile_updated'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('modules.notifications.canNotUpdateProfile'),
      }),
  });

  const createUserAvatar = (url: string) => {
    setAvatarUploading(false);
    AnalyzeService.recordAvatars(url);
    setAvatar({ ...avatar, editorModalOpen: false, img: url });
  };

  const setEditorRef = (ed: any) => {
    editor = ed;
  };

  const handleSlider = (value: number) =>
    setAvatar({
      ...avatar,
      scale: value * 0.075,
    });

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();

      canvasScaled.toBlob((blob: any) => {
        const file = new File([blob], 'uploaded-img.jpeg', {
          type: 'image/jpeg',
        });

        v3ApiService
          .getS3ImagePolicy(file.type, file.name)
          .then((policy) => {
            let formData = new FormData();

            formData.append('key', policy.Key);
            formData.append('Content-Type', policy.ContentType);
            formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
            formData.append('success_action_status', '201');
            formData.append('policy', policy.S3Policy);
            formData.append('signature', policy.S3Signature);
            formData.append('file', file);

            setAvatarUploading(true);

            axios
              .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData)
              .then(() => {
                if (!policy.CloudFrontLink) return;

                !!userId
                  ? updateUserAvatar.mutate(policy.CloudFrontLink)
                  : createUserAvatar(policy.CloudFrontLink);
              })
              .catch((err) => {
                console.log(err);
                setToast({ show: true, status: 'error', title: 'Error uploading avatar' });
              });
          })
          .catch((err) => {
            console.log(err);
            setToast({ show: true, status: 'error', title: 'Error uploading banner image' });
          });
      });
    }
  };

  return (
    <VStack>
      <AvatarEditor
        ref={setEditorRef}
        image={avatar.img}
        width={250}
        height={250}
        border={50}
        color={[0, 0, 0, 0.6]} // RGBA
        scale={avatar.scale}
        rotate={0}
      />

      <Slider
        aria-label="slider-ex-1"
        defaultValue={20}
        min={1}
        max={100}
        onChange={(value: number) => handleSlider(value)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>

      <Button ref={editor} onClick={() => handleSave()}>
        {t('global.action.save')}
      </Button>
    </VStack>
  );
}
