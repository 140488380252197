export const Progress = {
  baseStyle: {
    filledTrack: {
      bg: 'brand.primary',
    },
  },
  variants: {
    hollow: {
      track: {
        bg: 'transparent',
        borderRadius: 4,
        border: '2px solid white',
        width: '90px',
        height: '8px',
      },
      filledTrack: {
        bg: 'white',
        borderRadius: 4,
      },
    },
    hollowPrimary: {
      track: {
        bg: 'transparent',
        borderRadius: 4,
        borderColor: 'brand.primary',
        borderWidth: '2px',
        width: '90px',
        height: '8px',
      },
      filledTrack: {
        bg: 'brand.primary',
        borderRadius: 4,
      },
    },
    create: {
      track: {
        background: 'brand.gold.20',
        height: '2px',
      },
      filledTrack: {
        background: 'brand.gold.100',
      },
    },
  },
};
