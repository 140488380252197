import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreateSaveAndExitButtonProps {
  onClick: () => void;
}

export default function CreateSaveAndExitButton({ onClick }: CreateSaveAndExitButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant="ghost" onClick={onClick}>
      {t('authoring.saveAndExit')}
    </Button>
  );
}
