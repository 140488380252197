import {
  Box,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import CreateNextButton from '@/client/components/admin/create/shared/buttons/CreateNextButton';
import CreatePreviousButton from '@/client/components/admin/create/shared/buttons/CreatePreviousButton';
import CreatePublishButton from '@/client/components/admin/create/shared/buttons/CreatePublishButton';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { ReactElement } from 'react';

interface TabControlsProps {
  tabOptionsLength: number;
  onProceed: () => void;
  canAdvance: boolean;
  menuOptions?: { label: string; onClick: () => void; enabled: boolean }[];
  buttonOptions?: ReactElement[];
}

export default function TabControls({
  tabOptionsLength,
  onProceed,
  canAdvance,
  menuOptions,
  buttonOptions,
}: TabControlsProps) {
  const { setPublishModalIsOpen, tabIndex, setTabIndex } = useCreateStore();

  return (
    <>
      {!menuOptions && !buttonOptions && <Box />}
      {menuOptions && (
        <Menu variant="create">
          <MenuButton as={IconButton} icon={<IoEllipsisHorizontal />} variant="createOutline" />

          <MenuList>
            {menuOptions.map((option) => {
              if (!option.enabled) return;

              return (
                <MenuItem key={option.label} onClick={option.onClick}>
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
      
      {buttonOptions && (
        <ButtonGroup>
          {buttonOptions.map((b: ReactElement) => b)}
        </ButtonGroup>
      )}

      <ButtonGroup>
        <CreatePreviousButton
          isDisabled={tabIndex === 0}
          onClick={() => setTabIndex(tabIndex - 1)}
        />

        {tabIndex < tabOptionsLength - 1 && (
          <CreateNextButton isDisabled={tabIndex === 0 && !canAdvance} onClick={onProceed} />
        )}

        {tabIndex === tabOptionsLength - 1 && (
          <CreatePublishButton onClick={() => setPublishModalIsOpen(true)} />
        )}
      </ButtonGroup>
    </>
  );
}

TabControls.defaultProps = {
  menuOptions: null,
  buttonOptions: null
};