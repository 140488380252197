export const Textarea = {
  variants: {
    adminInput: {
      background: 'rgba(249, 249, 249, 1)',
      fontSize: '14px',
      borderRadius: '5px',
      _placeholder: {
        color: 'brand.grey.60',
      },
    },
    outline: {
      _focus: {
        border: '1px',
        borderColor: 'brand.primary',
        boxShadow: 'none',
      },
    },
    landingPage: {
      _placeholder: { 
        color: "brand.grey.60" 
      },
      backgroundColor: "#ffffff",
      border: '1px solid #E2E8F0',
      borderRadius: "md",
      variant: "unstyled",
      marginRight: 6,
      paddingLeft: 4,
      marginBottom: 4,
      paddingY: 2,
      fontSize: "14px"
    }
  },
};
