import { create } from 'zustand';

interface CreateState {
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
  canAdvance: boolean;
  setCanAdvance: (canAdvance: boolean) => void;
  discardModalIsOpen: boolean;
  setDiscardModalIsOpen: (discardModalIsOpen: boolean) => void;
  noImageModalIsOpen: boolean;
  setNoImageModalIsOpen: (noImageModalIsOpen: boolean) => void;
  publishModalIsOpen: boolean;
  setPublishModalIsOpen: (publishModalIsOpen: boolean) => void;
}

export const useCreateStore = create<CreateState>((set) => ({
  tabIndex: 0,
  setTabIndex: (tabIndex) => set({ tabIndex }),
  canAdvance: false,
  setCanAdvance: (canAdvance) => set({ canAdvance }),
  discardModalIsOpen: false,
  setDiscardModalIsOpen: (discardModalIsOpen) => set({ discardModalIsOpen }),
  noImageModalIsOpen: false,
  setNoImageModalIsOpen: (noImageModalIsOpen) => set({ noImageModalIsOpen }),
  publishModalIsOpen: false,
  setPublishModalIsOpen: (publishModalIsOpen) => set({ publishModalIsOpen }),
}));
