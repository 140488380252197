import { Category } from '@/client/types/Category';
import { create } from 'zustand';

// TODO: Rename this store to `connectStore` or something similar

const defaultLanguage = {
  name: 'language.english',
  key: 'language.english',
  abr: 'en',
  value: 'en',
};

interface Language {
  name: string;
  key: string;
  abr: string;
  value: string;
}

interface CategoriesState {
  selectedEditItem: Category | null;
  setSelectedEditItem: (item: Category | null) => void;
  defaultLanguage: Language;
  selectedLanguage: Language;
  setSelectedLanguage: (language: Language) => void;
  activeMainCategory: Category | null;
  setActiveMainCategory: (category: Category | null) => void;
  activeSubCategory: Category | null;
  setActiveSubCategory: (category: Category | null) => void;
  activeSubSubCategory: Category | null;
  setActiveSubSubCategory: (category: Category | null) => void;
}

export const useCategoriesStore = create<CategoriesState>((set) => ({
  selectedEditItem: null,
  setSelectedEditItem: (item) => set({ selectedEditItem: item }),
  defaultLanguage,
  selectedLanguage: defaultLanguage,
  setSelectedLanguage: (language) => set({ selectedLanguage: language }),
  activeMainCategory: null,
  setActiveMainCategory: (category) => set({ activeMainCategory: category }),
  activeSubCategory: null,
  setActiveSubCategory: (category) => set({ activeSubCategory: category }),
  activeSubSubCategory: null,
  setActiveSubSubCategory: (category) => set({ activeSubSubCategory: category }),
}));
