import { Person } from "@/client/types/Person";
import { learnApiClient } from "../../clients/learnApiClient";

const getSegmentationList = async (
  options: { pageIndex: number; pageSize: number },
  userIds: string[]
): Promise<{ rows: Person[]; pageCount: number }> => {
  const response = await learnApiClient.post(
    `users/segmentation-list?fields=_id,first_name,last_name,email,full_name,image,job_title,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,role,hired,createdAt,last_login&linked_departments-populate=name&linked_grade-populate=name&linked_locations-populate=name&linked_org_level-populate=name&linked_teams-populate=name&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}`,
    {
      in: userIds,
      nin: [],
      queries: [],
    }
  );

  return {
    rows: response.data,
    pageCount: Math.ceil(userIds.length / options.pageSize) || -1,
  };
};

const SegmentationService = {
  getSegmentationList,
};

export default SegmentationService;
