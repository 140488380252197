/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Circle,
  Flex,
  Icon,
  Input,
  InputGroup,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { useTranslation } from 'react-i18next';
import { CiCircleCheck, CiCirclePlus } from 'react-icons/ci';
import { People, ShoppingBag } from '../../icons/ContinuIcons';
import { useFormContext } from 'react-hook-form';

// TODO: Debounce search input

type TagListProps = {
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string | undefined) => void;
  data: any;
  selectedItemChange: (selectedItem: any) => void;
}


export default function ProductsCreateTagList({
  searchTerm, 
  setSearchTerm, 
  data, 
  selectedItemChange, 
}: TagListProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const selectedItems = watch('products');

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      setSearchTerm(inputValue);
    },
    items: data || [],
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItemChange(selectedItem);
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: '', // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '', // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
  });
  return (
    <>
          <InputGroup>
              <Input
                variant="landingPage"
                width="100%"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={`${t('ecommerce.landingPageCreate.placeholder.productsListed')}`}
                {...getInputProps()}
              />

            </InputGroup>

            <List
              flexDirection="column"
              border={data && data.length > 0 ? '1px solid' : 'none'}
              borderColor="brand.grey.20"
              borderRadius="md"
              background="white"
              overflow="scroll"
              zIndex={9999}
              maxH={400}
              width="inherit"
              display={data?.length === 0 ? 'none' : 'flex'}
              {...getMenuProps()}
            >
             {data?.map((option: any, optionIndex: any) => {

              const isSelected = selectedItems?.length && selectedItems.includes(option.id);

              return (
                <ListItem
                  key={`option_index_${optionIndex + 1}`}
                  paddingX={6}
                  paddingY={2}
                  _hover={{
                    cursor: 'pointer',
                    backgroundColor: 'brand.mist'

                  }}
                  {...getItemProps({
                    item: option,
                    index: optionIndex,
                  })}
                >
                  <Flex
                    alignItems="center"
                    width="full"
                    justifyContent="space-between"
                  >
                   <Circle
                      backgroundColor={isSelected ? 'gray.50' : 'gray.100'}
                      size="30px"
                    >
                      <Icon
                        color={
                          isSelected
                            ? 'brand.legibleBlack.50'
                            : 'brand.legibleBlack.100'
                        }
                        // ToDo: Figure out desired Icon
                        as={ShoppingBag}
                      />
                    </Circle>
                   

                    <Box flexGrow={1} textAlign="left" paddingLeft={4}>
                      <Text
                        fontSize="sm"
                        fontWeight={500}
                        color={
                          isSelected
                            ? 'brand.legibleBlack.50'
                            : 'brand.legibleBlack.100'
                        }
                      >
                        {option.title}
                      </Text>
                    </Box>

                    <Icon
                      boxSize={8}
                      as={isSelected ? CiCircleCheck : CiCirclePlus}
                      color={isSelected ? 'brand.primary' : 'brand.grey.40'}
                    />
                  </Flex>
                </ListItem>
              )})}
            </List>        
          </>

    )
  }
