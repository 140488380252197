import {
  Button,
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

// eslint-disable-next-line import/extensions
import discardImage from '@/client/assets/images/discard.svg';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';

interface DiscardModalProps {
  onDiscard: () => void;
}

export default function DiscardModal({ onDiscard }: DiscardModalProps) {
  const { t } = useTranslation();
  const { discardModalIsOpen, setDiscardModalIsOpen } = useCreateStore();

  const isOpen = discardModalIsOpen;

  const onClose = () => setDiscardModalIsOpen(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody>
          <VStack textAlign="center">
            <Image src={discardImage} width={100} height={100} />

            <Text fontSize="20px" fontWeight={600}>
              {t('authoring.discardModal.areYouSure')}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <Button variant="createOutline" onClick={onClose}>
              {t('authoring.discardModal.discardProgess')}
            </Button>

            <Button variant="createSolid" onClick={onDiscard}>
              {t('authoring.keepEditing')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
