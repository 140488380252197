import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form } from '../types/RegistrationForms';
import DynamicForm from './registration-forms/DynamicForm';
import ProductSelection from './products/ProductSelection';
import Logo from '../components/media/images/Logo';
import highFiveImage from '@/client/assets/images/highFive.svg';


interface RegisterWithProductSelectionProps {
  formData: Form;
}

enum Pages {
  LandingPageCreation = 'landingPageCreation',
  RegistrationForm = 'registrationForm',
}

export default function RegisterWithProductSelection({
  formData,
}: RegisterWithProductSelectionProps) {
  const [currentPage, setCurrentPage] = useState<string>(Pages.LandingPageCreation);
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  const nextPage = () => {
    setCurrentPage(Pages.RegistrationForm);
  };

  // TODO: Add go back to product select page?
  const goBack = () => {
    setCurrentPage(Pages.LandingPageCreation);
  };

  return (
    <Box>
      {currentPage === Pages.LandingPageCreation && (
        <Controller
          name="purchaseProductId"
          control={control}
          rules={{
            required: { value: true, message: t('global.forms.field_required') },
          }}
          render={({ field: { onChange, value } }) => (
            <ProductSelection
              form={formData}
              value={value}
              selectProduct={(id) => onChange(id)}
              nextPage={nextPage}
            />
          )}
        />
      )}
      {currentPage === Pages.RegistrationForm && (
        <Flex direction="row" width="100vw" justify="space-between">
          <Flex width="50%" justifyContent="center">
            <Flex
              minHeight="100vh"
              marginTop="15%"
              justify="center"
              width="500px"
              maxWidth="100%"
              zIndex="2"
            >
              <Stack spacing={8} width="100%" paddingY={12} paddingX={6} >
                <Box rounded="lg" boxShadow="lg" backgroundColor="white" padding={8} width="100%">
                  <Box marginTop={4} marginBottom={8} textAlign="center">
                    <Logo variant="login" />
                  </Box>
                  <DynamicForm fields={formData.fields} />
                </Box>
              </Stack>
            </Flex>
            </Flex>
          <Stack backgroundColor="brand.mist" width="50%" >
            <Image
              src={highFiveImage}
              width={200}
              height={200}
              margin="20% auto"
              marginBottom={4}
            />
            <Box textAlign="center" margin="0 auto" width="50%">
              <Text fontSize="2xl" fontWeight="600">{t('ecommerce.registrationPage.welcome')}</Text>
              <Text fontSize="sm" marginBottom={4}>{t('ecommerce.registrationPage.createAccount')}</Text>
              <Text fontSize="sm">{t('ecommerce.registrationPage.noCharge')}</Text>
            </Box>
          </Stack>
        </Flex>
      )}
    </Box>
  );
}
