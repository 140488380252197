import { Box, Flex } from '@chakra-ui/react';

type ContentHeroProps = {
  imageUrl: string;
};

export default function ContentHero({ imageUrl }: ContentHeroProps) {
  return (
    <Flex
      h={{ base: '200px', md: '300px', lg: '400px' }}
      w={'full'}
      backgroundImage={`url(${imageUrl})`}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
    >
      <Box w={'full'} h={'full'} bgColor={'blackAlpha.600'}></Box>
    </Flex>
  );
}
