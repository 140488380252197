import { Config } from '@/client/types/Config';
import {create } from 'zustand';

interface ConfigState {
  config: Config;
  setConfig: (config: Config) => void;
}

export const useConfigStore = create<ConfigState>((set) => ({
  config: {theme: {}, features: {}, customCopy:{}, backgroundInfo: {}, froalaConfig: {}, ticketingBrand: {}} as Config,
  setConfig: (config) => set({ config }),
}));
