import { NewUser } from '../../types/UserManagement';
import { Skill } from '@/client/types/Skill';
import { learnApiClient } from './clients/learnApiClient';
import { learnV4ApiClient } from './clients/learnV4ApiClient';

const createUser = async (user: NewUser): Promise<NewUser> => {
  const response = await learnApiClient.post(`users`, {
    ...user,
  });
  return response.data;
};

const updateUser = async (id: string, updateBody: NewUser): Promise<NewUser> => {
  const response = await learnApiClient.post(`users/${id}`, {
    ...updateBody,
  });
  return response.data;
};

const getUser = async (id: string | undefined) => {
  const response = await learnApiClient.get(
    `users/${id}?fields=first_name,last_name,full_name,email,role,image,is_manager,is_collaborator,metadata,banner_image,manager_email,buddy_email,managing_groups,access_profile,skills,social_links,about,hired,job_title,linked_departments,linked_locations,provisioning_status,language&skills-populate=name&linked_locations-populate=name&linked_departments-populate=name&company-populate=user_metadata_fields`,
  );
  return response.data;
};

const getUserForEditor = async (id: string | undefined): Promise<NewUser> => {
  const response = await learnV4ApiClient.get(
    `admin-users/${id}?fields=first_name,last_name,userid,full_name,email,role,image,is_manager,is_collaborator,manager_email,buddy_email,managing_groups,manager_configuration,access_profile,hired,createdAt,last_login,job_title,linked_departments,linked_locations,linked_teams,linked_org_level,linked_grade,groups,provisioning_status,language,partner,metadata,partner_role,address,country,distributor,region,group&company-populate=user_metadata_fields,`,
  );
  return response.data;
};

const updateBannerImage = async (id: string, banner_image: string) =>
  learnApiClient.post(`users/${id}?fields=banner_image`, {
    banner_image,
  });

const updateAvatar = async (id: string, image: string) => {
  const response = await learnApiClient.post(`users/${id}?fields=image`, {
    image,
  });

  return response.data.image;
};

const updateSocialProfiles = (id: string, social_links: { name: string; value: string }[]) =>
  learnApiClient.post(`users/${id}?fields=social_links`, { social_links });

const updateSkills = (skills: Skill[], userId: string) => {
  const skillIds = skills.map((skill) => skill._id);

  const response = learnApiClient.post(
    `users/${userId}?fields=first_name,last_name,full_name,email,role,is_collaborator,image,banner_image,skills,social_links,about,provisioning_status,language&skills-populate=name`,
    {
      skills: skillIds,
    },
  );

  return response;
};

const updateDetails = async (
  id: string,
  about: string,
  first_name: string,
  language: string,
  last_name: string,
) => {
  const response = await learnApiClient.post(
    `users/${id}?fields=first_name,last_name,about,language`,
    {
      about,
      first_name,
      language,
      last_name,
    },
  );
  return response.data;
};

const getCommentCount = async (_id: string | undefined) => {
  const response = await learnApiClient.get(`maincomments/count?user=${_id}`);

  return response.data;
};

const getComments = async (_id: string | undefined) => {
  const response = await learnApiClient.get(
    `maincomments?article-populate=title&assessment-populate=title&event-populate=title&fields=article,media,track,presentation,imported_content,assessment,event,createdAt,user&imported_content-populate=title&media-populate=title,type&presentation-populate=title&sort=createdAt,-1&track-populate=name&user=${_id}`,
  );

  return response.data;
};

const getSuggestions = async (_id: string | undefined) => {
  const response = await learnApiClient.get(
    `suggestions?page=1&per_page=1&article-populate=title&assessment-populate=title&event-populate=title&fields=article,media,track,presentation,imported_content,assessment,event,createdAt,user&imported_content-populate=title&media-populate=title,type&presentation-populate=title&sort=createdAt,-1&track-populate=name&user=${_id}`,
  );

  return response.data;
};

const updateCustomFields = async (userId: string, metadata: any) =>
  learnApiClient.post(`users/${userId}?fields=metadata`, { metadata });

const User = {
  createUser,
  updateUser,
  getUser,
  getUserForEditor,
  updateBannerImage,
  updateAvatar,
  updateSocialProfiles,
  updateSkills,
  updateDetails,
  getCommentCount,
  getComments,
  getSuggestions,
  updateCustomFields,
};

export default User;
