import { Controller, useFormContext } from 'react-hook-form';

import AdminElevatedBox from '../../layout/AdminElevatedBox';
import AdminUsersGroupSearch from './AdminUsersGroupSearch';
import AdminUsersTeamsSearch from './AdminUsersTeamsSearch';
import HeadingWithTooltip from '../HeadingWithTooltip';
import SegmentationFormField from '@/client/components/forms/SegmentationFormField';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function Segmentation() {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const parseValue = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) {
      return value.length ? value[0] : '';
    }
    if (value?.length) {
      return value;
    }
    return '';
  };

  const updateArrayCategory = (onChange: (...event: any[]) => void, type: string, id: string) =>
    id ? onChange([id]) : onChange([]);

  return (
    <AdminElevatedBox>
      <Stack spacing={6}>
        <HeadingWithTooltip
          title={t('edit.general.headlines_segmentation')}
          helpText={t('registrationForms.tooltip.segmentation')}
        />

        <Controller
          name="linked_locations"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SegmentationFormField
              fieldLabel={t('global.forms.labels_location')}
              helpLabel={t('global.forms.labels_location')}
              type="location"
              initialId={parseValue(value)}
              updateCategory={(type: string, id: string) => updateArrayCategory(onChange, type, id)}
            />
          )}
        />

        <Controller
          name="linked_departments"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SegmentationFormField
              fieldLabel={t('global.forms.labels_department')}
              helpLabel={t('global.forms.labels_department')}
              type="department"
              initialId={parseValue(value)}
              updateCategory={(type: string, id: string) => updateArrayCategory(onChange, type, id)}
            />
          )}
        />

        <Controller
          name="linked_grade"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SegmentationFormField
              fieldLabel={t('manage.label_grade')}
              helpLabel={t('manage.label_grade')}
              type="grade"
              initialId={parseValue(value)}
              updateCategory={(type: string, id: string) => onChange(id || null)}
            />
          )}
        />

        <Controller
          name="linked_org_level"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SegmentationFormField
              fieldLabel={t('manage.label_org_level')}
              helpLabel={t('manage.label_org_level')}
              type="org_level"
              initialId={parseValue(value)}
              updateCategory={(type: string, id: string) => onChange(id || null)}
            />
          )}
        />

        <AdminUsersTeamsSearch />

        <AdminUsersGroupSearch />
      </Stack>
    </AdminElevatedBox>
  );
}
