/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Progress, Text, useMultiStyleConfig, useTab } from '@chakra-ui/react';

import { forwardRef } from 'react';

export const CreateTab = forwardRef((props: any, ref) => {
  const { index, label, tabIndex } = props;
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  const styles = useMultiStyleConfig('Tabs', tabProps);

  const setLabelColor = () => {
    if (isSelected || tabIndex >= index) {
      return 'brand.legibleBlack.100';
    }

    return 'brand.legibleBlack.10';
  };

  const setProgressValue = () => {
    if (isSelected) return 20;

    if (tabIndex > index) return 100;

    return 0;
  };

  return (
    <Button textAlign="left" flex={1} marginX={6} __css={styles.tab} {...tabProps}>
      <Progress variant="create" marginBottom={2} value={setProgressValue()} />

      <Text fontWeight={600} color={setLabelColor()}>
        {label}
      </Text>
    </Button>
  );
});
