import {
  Avatar,
  Button,
  Center,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import AdminElevatedBox from '../../layout/AdminElevatedBox';
import Editor from '@/client/components/media/images/avatar/Editor';
import HeadingWithTooltip from '../HeadingWithTooltip';
import InputWithToolTip from '../InputWithTooltip';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

type EditModeImage = {
  editorModalOpen: boolean;
  img: string | File;
  scale: number;
  croppedImg: null | File;
};

// TODO: look into adding watch for getting image value

export default function UserAvatar() {
  const { t } = useTranslation();
  const { getValues, setValue, control } = useFormContext();
  const { setToast } = useToastStore();
  const avatarRef = useRef<HTMLInputElement>(null);
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [avatar, setAvatar] = useState<EditModeImage>({
    editorModalOpen: false,
    img: getValues('image') || '',
    scale: 1.5,
    croppedImg: null,
  });

  const formImage = useWatch({ control, name: 'image' });

  const handleAvatarInputClick = () => {
    avatarRef.current!.click();
  };

  const handleAvatarRemove = () => {
    setValue('image', '');
    setAvatar({ ...avatar, editorModalOpen: false, img: '', scale: 1.5, croppedImg: null });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;
    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: 'error', title: 'Error uploading image' });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    setAvatar({
      ...avatar,
      img: fileObject,
      editorModalOpen: true,
    });
  };

  useEffect(() => {
    if (typeof avatar.img === 'string' && avatar.img.length) {
      setValue('image', avatar.img);
    }
  }, [avatar.img]);

  return (
    <>
      <AdminElevatedBox>
        <HeadingWithTooltip
          title={t('edit.useradd.tab.avatar')}
          helpText={t('edit.general.headlines_addImages')}
        />

        <Flex direction="row" gap={8} alignItems="center">
          <Avatar src={formImage} />

          <Flex direction="column">
            <Button variant="ghost" onClick={handleAvatarInputClick}>
              {typeof avatar.img === 'string' && !avatar.img.length
                ? t('edit.general.avatar_upload')
                : t('edit.general.avatar_replace')}
            </Button>

            <Input
              type="file"
              display="none"
              ref={avatarRef}
              onChange={(e) => handleFileChange(e)}
            />

            <Button variant="ghost" color="red" onClick={handleAvatarRemove}>
              {t('edit.general.avatar_remove')}
            </Button>
          </Flex>

          <InputWithToolTip
            name="image"
            label={t('edit.useradd.imageUrl')}
            placeholder={`${t('global.forms.labels_avatarLink')}`}
            tooltipText={t('global.forms.labels_avatarLink')}
          />
        </Flex>
      </AdminElevatedBox>

      <Modal
        isCentered
        isOpen={avatar.editorModalOpen}
        onClose={() =>
          setAvatar({
            editorModalOpen: false,
            img: '',
            scale: 1.5,
            croppedImg: null,
          })
        }
      >
        <ModalOverlay />

        <ModalContent padding={4}>
          <Center>
            {avatarUploading && <Spinner />}

            {!avatarUploading && (
              <Editor
                avatar={avatar}
                setAvatar={setAvatar}
                setAvatarUploading={setAvatarUploading}
              />
            )}
          </Center>
        </ModalContent>
      </Modal>
    </>
  );
}
