import { Box } from '@chakra-ui/react';
import ConnectInput from '@/client/components/admin/connect/ConnectInput';
import SingleColumnList from '../../admin/lists/SingleColumnList';
import { UseConnectProps } from '@/client/services/hooks/admin/connect/useConnect';

interface SingleColumnLayoutProps {
  type: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade' | 'skill';
  placeholderType: string;
  showInput?: boolean;
  noSearchResultsText: string;
  connect: UseConnectProps;
}

export default function SingleColumnLayout({
  type,
  placeholderType,
  showInput,
  noSearchResultsText,
  connect,
}: SingleColumnLayoutProps) {
  return (
    <Box backgroundColor="white" paddingX={6} paddingY={2} marginX={4} marginY={2}>
      {showInput && !connect.isSearching && (
        <ConnectInput
          onAddItem={connect.addItem}
          level={1}
          parentId={null}
          type={type}
          placeholderType={placeholderType}
        />
      )}

      <SingleColumnList
        items={connect.isSearching ? connect.searchItems : connect.items}
        connect={connect}
        noSearchResultsText={noSearchResultsText}
      />
    </Box>
  );
}

SingleColumnLayout.defaultProps = {
  showInput: true,
};
