import { PersistOptions, persist } from 'zustand/middleware';
import { create, StateCreator } from 'zustand';
import { setUserInfo, trackEvent } from '@/client/utils/AnalyticsProvider';

import { AuthConfig } from '../../types/AuthConfig';
import { usePartnerStore } from './partnerStore';
import { useExploreStore } from './routeState/exploreStore';

let learnAppActivityTTL = 4 * 60 * 60 * 1000;

interface AuthState {
  authConfig: AuthConfig;
  setAuthConfig: (authConfig: AuthConfig) => Promise<void>;
  bumpActive: () => void;
  isLastActiveNearExpiration: (expiresWithin: number) => boolean;
  logout: () => void;
}

type AuthPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>;

export const AUTH_LAST_ACTIVE_KEY = 'last_active';
export const AUTH_AUTHTOKEN_KEY = 'authtoken';

const bumpActive = () => {
  // Set the configuration for learn-app's login needs.
  localStorage[AUTH_LAST_ACTIVE_KEY] = JSON.stringify(
    new Date().valueOf() + learnAppActivityTTL
  );
};

const isLastActiveNearExpiration = (expiresWithin: number) => {
  const lastActive = localStorage[AUTH_LAST_ACTIVE_KEY];
  if (lastActive) {
    const lastActiveDate = new Date(parseInt(lastActive, 10));
    const now = new Date();
    const diff = lastActiveDate.valueOf() - now.valueOf();
    return diff < expiresWithin;
  }
  return false;
};

export const useAuthStore = create<AuthState>(
  (persist as AuthPersist)(
    (set) => ({
      authConfig: {} as AuthConfig,
      setAuthConfig: async (authConfig) => {
        setUserInfo(authConfig); // TODO: why isnt this being set on login?
        set({ authConfig });
        localStorage[AUTH_AUTHTOKEN_KEY] = JSON.stringify(authConfig);
        bumpActive();
      },
      bumpActive: () => {
        bumpActive();
      },
      isLastActiveNearExpiration: (expiresWithin: number) =>
        isLastActiveNearExpiration(expiresWithin),
      logout: () => {
        trackEvent('signout');
        set({ authConfig: {} as AuthConfig });

        // Also set the configuration for learn-app's login needs.
        localStorage.removeItem(AUTH_AUTHTOKEN_KEY);
        localStorage.removeItem(AUTH_LAST_ACTIVE_KEY);
        localStorage.removeItem("partnerStore");

        // Centralizing logout behavior to make sure timeouts and explicit logouts do the same thing.
        const partnerId = usePartnerStore.getState().partner._id;
        usePartnerStore.getState().clearPartnerStore();
        useExploreStore.getState().clearExploreStore();
        if (partnerId) {
          window.location.href = `/login/extend/${partnerId}`;
        } else {
          window.location.href = `/login`;
        }
      },
    }),
    { name: 'authStore' }
  )
);

export const setLearnAppActivityTTL = (ttl: number) => {
  learnAppActivityTTL = ttl;
};
