import { Grid, GridItem } from '@chakra-ui/react';

import ThreeColumnList from './ThreeColumnList';
import { UseConnectProps } from '@/client/services/hooks/admin/connect/useConnect';
import PartnerPermissionModal from '@/client/components/admin/overlay/PartnerPermissionModal';
import update from 'immutability-helper';
import { useCallback } from 'react';
import { useCategoriesStore } from '@/client/services/state/admin/categoriesStore';
import { useTranslation } from 'react-i18next';

interface ThreeColumnLayoutProps {
  isDragDisabled?: boolean;
  placeholderType: string;
  showExternal?: boolean;
  type: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade';

  columnCopy: {
    columnOneTitle: string;
    columnTwoTitle: string;
    columnThreeTitle: string;
    noSubItemsText: string;
    noSubSubItemsText: string;
    noResultsMain: string;
    noResultsSub: string;
    noResultsSubSub: string;
  };

  connect: UseConnectProps;
}

export default function ThreeColumnLayout({
  isDragDisabled,
  placeholderType,
  showExternal,
  type,
  columnCopy,
  connect,
}: ThreeColumnLayoutProps) {
  const { t } = useTranslation();
  const { activeMainCategory, activeSubCategory } = useCategoriesStore();

  const moveLevelOneCard = useCallback((dragIndex: number, hoverIndex: number) => {
    connect.setItems((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const moveLevelTwoCard = useCallback((dragIndex: number, hoverIndex: number) => {
    connect.setSubItems((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const moveLevelThreeCard = useCallback((dragIndex: number, hoverIndex: number) => {
    connect.setSubSubItems((prevCards: any[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const checkSecondLevelInput = () => {
    if (!activeMainCategory || activeMainCategory.level === 10 || connect.isSearching) {
      return false;
    }
    return true;
  };

  const checkThirdLevelInput = () => {
    if (!activeSubCategory || activeMainCategory?.level === 10 || connect.isSearching) {
      return false;
    }

    return true;
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} padding={4}>
      <GridItem>
        <ThreeColumnList
          items={connect.items}
          title={columnCopy.columnOneTitle}
          type={type}
          placeholderType={placeholderType}
          connect={connect}
          handleMoveCardFunc={moveLevelOneCard}
          noSearchResultsCopy={columnCopy.noResultsMain}
          isDragDisabled={isDragDisabled || false}
          showExternal={showExternal || false}
          noItemsText={t('global.forms.labels_noResults')}
          level={1}
          showInput={!connect.isSearching}
          parentId={null}
          isLoading={connect.levelOneIsFetchingNextPage}
          fetchNextPage={() => connect.levelOneFetchNextPage()}
        />
      </GridItem>

      <GridItem>
        <ThreeColumnList
          items={connect.subItems}
          title={columnCopy.columnTwoTitle}
          type={type}
          placeholderType={placeholderType}
          connect={connect}
          handleMoveCardFunc={moveLevelTwoCard}
          noSearchResultsCopy={columnCopy.noResultsSub}
          isDragDisabled={isDragDisabled || false}
          showExternal={showExternal || false}
          noItemsText={
            activeMainCategory?.level === 10
              ? t('admin.connect.categories.noFeaturedSecondary')
              : columnCopy.noSubItemsText
          }
          level={2}
          showInput={checkSecondLevelInput()}
          parentId={activeMainCategory?._id}
          isLoading={connect.levelTwoIsFetchingNextPage}
          fetchNextPage={() => connect.levelTwoFetchNextPage()}
        />
      </GridItem>

      <GridItem>
        <ThreeColumnList
          items={connect.subSubItems}
          title={columnCopy.columnThreeTitle}
          type={type}
          placeholderType={placeholderType}
          connect={connect}
          handleMoveCardFunc={moveLevelThreeCard}
          noSearchResultsCopy={columnCopy.noResultsSubSub}
          isDragDisabled={isDragDisabled || false}
          showExternal={showExternal || false}
          noItemsText={
            activeMainCategory?.level === 10
              ? t('admin.connect.categories.noFeaturedTertiary')
              : columnCopy.noSubSubItemsText
          }
          level={3}
          showInput={checkThirdLevelInput()}
          parentId={activeSubCategory?._id}
          isLoading={connect.levelThreeIsFetchingNextPage}
          fetchNextPage={() => connect.levelThreeFetchNextPage()}
        />
      </GridItem>
    </Grid>

  );
}

ThreeColumnLayout.defaultProps = {
  showExternal: false,
  isDragDisabled: false,
};
