export const getSupportedLanguages = () => [
  {
    name: 'language.english',
    key: 'language.english',
    abr: 'en',
    value: 'en',
  },
  {
    name: 'language.arabic',
    key: 'language.arabic',
    abr: 'ar',
    value: 'ar',
  },
  {
    name: 'language.chinese_simple',
    key: 'language.chinese_simple',
    abr: 'zh_CN',
    value: 'zh_CN',
  },
  {
    name: 'language.chinese_traditional',
    key: 'language.chinese_traditional',
    abr: 'zh_TW',
    value: 'zh_TW',
  },
  {
    name: 'language.french',
    key: 'language.french',
    abr: 'fr',
    value: 'fr',
  },
  {
    name: 'language.french_canadian',
    key: 'language.french_canadian',
    abr: 'fr_CA',
    value: 'fr_CA',
  },
  {
    name: 'language.german',
    key: 'language.german',
    abr: 'de',
    value: 'de',
  },
  {
    name: 'language.hindi',
    key: 'language.hindi',
    abr: 'hi_IN',
    value: 'hi_IN',
  },
  {
    name: 'language.indonesian',
    key: 'language.indonesian',
    abr: 'id',
    value: 'id',
  },
  {
    name: 'language.italian',
    key: 'language.italian',
    abr: 'it',
    value: 'it',
  },
  {
    name: 'language.japanese',
    key: 'language.japanese',
    abr: 'ja',
    value: 'ja',
  },
  {
    name: 'language.korean',
    key: 'language.korean',
    abr: 'ko',
    value: 'ko',
  },
  {
    name: 'language.polish',
    key: 'language.polish',
    abr: 'pl',
    value: 'pl',
  },
  {
    name: 'language.portuguese_br',
    key: 'language.portuguese_br',
    abr: 'pt_BR',
    value: 'pt_BR',
  },
  {
    name: 'language.russian',
    key: 'language.russian',
    abr: 'ru',
    value: 'ru',
  },
  {
    name: 'language.spanish_la',
    key: 'language.spanish_la',
    abr: 'es_LA',
    value: 'es_LA',
  },
  {
    name: 'language.spanish_es',
    key: 'language.spanish_es',
    abr: 'es_ES',
    value: 'es_ES',
  },
  {
    name: 'language.swedish',
    key: 'language.swedish',
    abr: 'sv_SE',
    value: 'sv_SE',
  },
  {
    name: 'language.vietnamese',
    key: 'language.vietnamese',
    abr: 'vi',
    value: 'vi',
  },
  {
    name: 'language.thai',
    key: 'language.thai',
    abr: 'th',
    value: 'th',
  },
];
