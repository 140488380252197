/* eslint-disable react/jsx-props-no-spreading */
import { Box, InputGroup, Stack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AuthoringContentList from '../../lists/AuthoringContentList';
import ContentSegmentationSearch from '@/client/components/admin/input/search/ContentSegmentationSearch';
import { ContentDetails, Product } from '@/client/services/api/graphql/gql/graphql';
import { AdminContent } from '@/client/types/content/AdminContent';

// TODO: Debounce search input
// TODO: Figure out a better way to determine isSelected

export default function AuthoringContentSearch() {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<Product>();
  const contentListDetails = watch('contentListDetails');

  const selectContents = (items: (AdminContent | ContentDetails)[]) => {
    const formattedContentDetails: ContentDetails[] = items.map((item) => {
      // ContentDetails
      if ('id' in item) {
        return item;
      }
      const displayName = item.title || item.name || '';
      return {
        id: item._id,
        image: item.image,
        type: item.type,
        displayName,
      };
    });
      setValue('contentListDetails', formattedContentDetails);
  };

  return (
    <Stack spacing={4} width="auto">
      <Text variant="createHeading">{t('authoring.tabs.addContent')}</Text>
      <Text variant="createHelpText">{t('authoring.product.addContentSubHeading')}</Text>

      <InputGroup>
        <Box width="full" backgroundColor="white">
          <ContentSegmentationSearch
            label=""
            contentOnly
            selectedItems={contentListDetails || []}
            setSelectedItems={selectContents}
            forType="contentAndJourneys"
            isRequired={false}
            includeJourneys
            noList
          />
        </Box>
      </InputGroup>

      <Stack spacing={4}>
        <AuthoringContentList />
      </Stack>
    </Stack>
  );
}
