import { Box, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import PlaceholderImage from '@/client/components/media/images/PlaceholderImage';
import { Purchase } from '@/client/services/api/graphql/gql/graphql';
import ProductCard from '../../data-display/cards/ProductCard';
import ProductsService from '@/client/services/api/graphql/ProductsService';

export default function DashboardPurchasesList({ userId }: { userId: string }) {
  const { t } = useTranslation();
 
  const {
    data,
  } = useQuery({
    queryKey: ['dashboard-puchases', userId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [userId] },
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  return (
    <>
      {!data?.purchases.length && (
        <Box padding={10} textAlign="center" fontSize="sm" color="gray.400">
          <PlaceholderImage name="house" />
          <Text>{t('dashboard.assignments.hasNoCompleted')}</Text>
        </Box>
      )}

      { data?.purchases && data.purchases.length > 0 && (
        <Stack spacing={4}>
          {data.purchases.map((purchase: Purchase) => {
            const formattedProductDetails = {
              title: purchase.productDetails.title,
              description: purchase.productDetails.description,
              price: purchase.productDetails.price,
              subscription_duration: purchase.productDetails.subscriptionDuration,
              _id: purchase.product,
              images: purchase.productDetails.images
            }

            return <ProductCard
              // @ts-ignore
              product={formattedProductDetails}
              onSelect={() => console.log(purchase.id)}
              onViewPayments={() => console.log(purchase.id)}
              nextBillingDate={purchase.nextScheduledPayment}
              isPurchased
            />
          })}
        </Stack>
      )}
    </>
  );
}
