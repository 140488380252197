import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { DefaultTFuncReturn } from "i18next";
import { useTranslation } from "react-i18next";

type PublishModalProps = {
  onClose: () => void;
  onPublish: () => void;
  headerText?: string | DefaultTFuncReturn;
  bodyText?: string | DefaultTFuncReturn;
};

export default function PublishModal({
  onClose,
  onPublish,
  headerText,
  bodyText,
}: PublishModalProps) {
  const { t } = useTranslation();

  return (
    <Modal size="xl" isOpen onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            {headerText || t('content.edit.publishHeader')}
          </ModalHeader>

          <ModalBody>
            <Stack spacing={4}>
              <Text as="p">{bodyText || t('admin.proceed')}</Text>
            </Stack>
          </ModalBody>

        <ModalFooter width="full" display="flex" justifyContent="space-between">
          <Button variant="adminCancel" mr={3} onClick={onClose}>
            {t('global.actions.cancel')}
          </Button>

          <Button variant="adminPrimary" onClick={onPublish}>
            {t('global.actions.publish')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

PublishModal.defaultProps = {
  headerText: null,
  bodyText: null,
};