import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreatePreviousButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

export default function CreatePreviousButton({ isDisabled, onClick }: CreatePreviousButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant="createOutline" isDisabled={isDisabled} onClick={onClick}>
      {t('authoring.previous')}
    </Button>
  );
}
