import { Box, Flex } from '@chakra-ui/react';
import { Navigate, Outlet } from 'react-router-dom';

import Logo from '@/client/components/media/images/Logo';
import { useAuthStore } from '@/client/services/state/authStore';

export default function LoggedOutFullScreenLayout() {
  const { authConfig } = useAuthStore();

  if (authConfig.authtoken) {
    return <Navigate to="/explore" replace />;
  }

  return (
     <Box>
        <Box marginX={8} marginY={4} textAlign="left">
          <Logo variant="explore" />
        </Box>
        <Flex
          minHeight="100vh"
          alignSelf="center"
          alignItems="self-start"
          justify="center"
          maxWidth="100%"
          zIndex="2"
        >
          <Outlet />
      </Flex>
      </Box>
  );
}
