import { learnApiClient } from './clients/learnApiClient';
import { Company, CustomField } from '@/client/types/AuthConfig';

const getCompany = async (companyId: string): Promise<Company> => {
  const response = await learnApiClient.get(`/companies?companyId=${companyId}`);
  return response.data;
};

const updateCompanyCustomFields = async (
  companyId: string,
  fields: CustomField[],
): Promise<Company> => {
  const response = await learnApiClient.post(`companies/${companyId}/custom-fields`, {
    customFields: fields,
  });
  return response.data;
};

const CompanyService = {
  getCompany,
  updateCompanyCustomFields,
};

export default CompanyService;
