import { Button, Divider, Grid, GridItem, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capCase } from '@/client/utils/capCase';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';

const formatDescription = (d: string) => {
  let formatted = d.replace(/(<p>|<\/p>)/g, '');
  if (formatted.length > 400) {
    formatted = formatted.slice(0, 400) + '...';
  }
  return formatted;
};

interface PublishDetailsSectionProps {
  label: string;
  infoComponent: ReactNode;
  onEdit: () => void;
}

function PublishDetailsSection({ label, infoComponent, onEdit }: PublishDetailsSectionProps) {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="repeat(3, 1fr)">
      <GridItem>
        <Text variant="createLabel">{label}</Text>
      </GridItem>

      <GridItem>{infoComponent}</GridItem>

      <GridItem>
        <Button variant="createGoldOutline" onClick={onEdit}>
          {t('authoring.publishPreview.editSection')}
        </Button>
      </GridItem>
    </Grid>
  );
}

interface PublishDescriptionSectionProps {
  desc: string;
  onEdit: () => void;
}

function PublishDescriptionSection({ desc, onEdit }: PublishDescriptionSectionProps) {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="2fr 1fr">
      <GridItem>
        <Text variant="createLabel">{t('global.forms.labels_description')}</Text>
        <Text variant="createHelpText">{formatDescription(desc)}</Text>
      </GridItem>

      <GridItem>
        <Button variant="createGoldOutline" onClick={onEdit}>
          {t('authoring.publishPreview.editSection')}
        </Button>
      </GridItem>
    </Grid>
  );
}

export default function ProductPublishForm() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const { t } = useTranslation();
  const { setTabIndex } = useCreateStore();
  const { watch } = useFormContext();

  const images = watch('images');
  const hasImage = images && images[0];

  const description = watch('description');

  const subscriptionDuration = watch('subscriptionDuration');
  const price = watch('price');

  return (
    <Stack spacing={6}>
      <Text fontSize="24px" fontWeight={600}>
        {watch('title')}
      </Text>

      <Divider variant="create" />

      <PublishDetailsSection
        label="Images"
        infoComponent={
          <Stack>
            <HStack>
              <Icon
                color={hasImage ? 'brand.gold.100' : 'red'}
                as={hasImage ? FaCheckCircle : FaTimesCircle}
              />

              <Text variant="createLabel">{t('authoring.publishPreview.thumbnailImage')}</Text>
            </HStack>
          </Stack>
        }
        onEdit={() => setTabIndex(0)}
      />

      <Divider variant="create" />

      <PublishDescriptionSection desc={description || ''} onEdit={() => setTabIndex(0)} />

      <Divider variant="create" />

      <PublishDetailsSection
        label="Settings"
        infoComponent={
          <Stack>
            <Stack>
              <Text variant="createLabel">{t('authoring.product.termLength')}</Text>
              <Text variant="createHelpText">
                {/* @ts-ignore */}
                {subscriptionDuration ? capCase(subscriptionDuration.toLowerCase()) : '-'}
              </Text>
            </Stack>
            <Stack>
              <Text variant="createLabel">{t('authoring.product.cost')}</Text>
              <Text variant="createHelpText">{`$${price || 0}`}</Text>
            </Stack>
          </Stack>
        }
        onEdit={() => setTabIndex(2)}
      />
    </Stack>
  );
}
