import { Box, Flex, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { CreateTab } from './CreateTab';
import type { ReactElement } from 'react';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { useSize } from '@chakra-ui/react-use-size';

interface CreateTabsProps {
  tabOptions: { label: string; content: any; isDisabled: boolean }[];
  controls: ReactElement;
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

export default function CreateTabs({
  tabOptions,
  controls,
  tabIndex,
  setTabIndex,
}: CreateTabsProps) {
  const { watch } = useFormContext();

  const updatedAt = watch('updatedAt');

  const containerRef = useRef<HTMLDivElement>(null);

  const { width } = useSize(containerRef) ?? { width: null };
  const windowWidth = window.innerWidth;

  const handleTabChange = (index: number) => setTabIndex(index);

  return (
    <Box ref={containerRef} marginBottom="500px">
      {updatedAt && (
        <Flex padding={4} justifyContent="flex-end">
          <Text variant="createHelpText">
            Autosaved at {format(new Date(updatedAt), 'h:mm ss a')}
          </Text>
        </Flex>
      )}

      <Tabs isLazy index={tabIndex} onChange={handleTabChange} padding={8}>
        <TabList width="full" display="flex" justifyContent="space-evenly">
          {tabOptions.map((tab, index) => (
            <CreateTab
              key={tab.label}
              index={index}
              label={tab.label}
              isDisabled={tab.isDisabled}
              tabIndex={tabIndex}
            />
          ))}
        </TabList>

        <TabPanels>
          {tabOptions.map((tab) => (
            <TabPanel key={tab.label}>{tab.content}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Flex
        zIndex={999}
        position="fixed"
        background="white"
        left={width ? windowWidth - width : '40%'}
        right={0}
        bottom={0}
        paddingY={8}
        paddingX={18}
        justifyContent="space-between"
      >
        {controls}
      </Flex>
    </Box>
  );
}
