import { Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import AuthoringContentSearch from '../../create/content/forms/audience/AuthoringContentSearch';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';

interface ProductAccessFormProps {
  canAdvance: boolean;
}

export default function ProductAccessForm({ canAdvance }: ProductAccessFormProps) {
  const { setCanAdvance } = useCreateStore();

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  return (
    <Stack spacing={6} marginX={6}>
      <AuthoringContentSearch />
    </Stack>
  );
}
