import {
  ContentDetails,
  PageInfo,
  Product,
  Purchase,
} from '@/client/services/api/graphql/gql/graphql';

export enum OrderingFields {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  title = 'title',
}

export type ProductListResults = {
  products: Product[];
  pageInfo: PageInfo;
}

export type PurchaseListResults = {
  purchases: Purchase[];
  pageInfo: PageInfo;
};

export interface AccessList {
  content?: ContentDetails[];
}

export const getCurrencySign = (currency: string) => {
  // TODO account for all currencies we will be supporting here
  switch(currency) {
    case ('USD'): {
      return '$';
    }
    default:
      return '';
  }
}