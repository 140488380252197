import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import SingleColumnLayout from '@/client/components/layout/admin/SingleColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useTranslation } from 'react-i18next';

export default function Teams() {
  const { t } = useTranslation();
  const connect = useConnect('team');

  const teamsPlural = t('admin.connect.teams.plural');
  const teamsSingular = t('admin.connect.teams.singular');

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: teamsSingular })}
        showSortSelector
        title={teamsPlural}
        setSearchTerm={connect.setSearchTerm}
        sortOrder={connect.sortOrder}
        setSortOrder={connect.setSortOrder}
        isSearching={connect.isSearching}
      />

      <SingleColumnLayout
        type="team"
        placeholderType={teamsSingular}
        noSearchResultsText={t('admin.connect.noSearchResultsMain', { type: teamsPlural })}
        connect={connect}
      />

      <DeleteItemModal deleteItem={connect.deleteItem} />
    </>
  );
}
