import { ImportedContent, Registration } from '@/client/types/content/ImportedContent';

import { learnApiClient } from './clients/learnApiClient';

const getImportedContent = async (
  contentId: string | undefined,
  userId: string
): Promise<{ content: ImportedContent; registration: Registration; progressPercent: number }> => {
  const content = await learnApiClient.get<ImportedContent>(
    `imported-content/${contentId}?fields=title,description,instructors,headline,learn_items,vendor,vendor_link,vendor_full_link,tags,image,banner_image,user,linked_category,explore_hide,duration,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,lil_structure,difficulty,allow_comments,groups,surveys,completed`
  );

  const registerUser = await register(contentId, userId);

  let progressPercent = 0;

  if (registerUser.results) {
    let latest_progress = registerUser.results.find(function (result: any) {
      return result.name === 'latest_progress';
    });

    progressPercent = registerUser.completed ? 100 : 0;

    if (latest_progress) {
      latest_progress.object = JSON.parse(latest_progress.value);
    }
  }

  return {
    content: content.data,
    registration: registerUser,
    progressPercent,
  };
};

const register = async (contentId: string | undefined, userId: string) => {
  const response = await learnApiClient.post(`imported-content/registrations/register`, {
    content: contentId,
    user: userId,
  });

  return response.data;
};

const ImportedContentService = {
  getImportedContent,
  register,
};

export default ImportedContentService;
