/* eslint-disable no-param-reassign */

import { Box, ButtonGroup, Flex, Input, Text, useToken } from '@chakra-ui/react';
import {
  Check,
  Close,
  Delete,
  EditSimple,
  People,
  ViewOutline,
  ViewSlash,
} from '@/client/components/icons/ContinuIcons';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { useEffect, useRef, useState } from 'react';

import { Category } from '@/client/types/Category';
import ConnectService from '@/client/services/api/admin/connect/ConnectService';
import { DragHandleIcon } from '@chakra-ui/icons';
import IconButtonWithTooltip from '../../buttons/IconButtonWithTooltip';
import { UseConnectProps } from '@/client/services/hooks/admin/connect/useConnect';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useAdminStore } from '@/client/services/state/admin/adminStore';
import { useCategoriesStore } from '@/client/services/state/admin/categoriesStore';
import { useLanguage } from '@/client/services/hooks/language/useLanguage';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useConfigStore } from '@/client/services/state/configStore';

interface CardProps {
  isDragDisabled?: boolean;
  category: Category;
  index: number;
  showExternalOption?: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  connect: UseConnectProps;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ItemTypes = {
  CARD: 'card',
};

export default function ConnectCard({
  isDragDisabled,
  category,
  index,
  showExternalOption,
  moveCard,
  connect,
}: CardProps) {
  const { t } = useTranslation();
  const { setDeleteModal, setPartnerPermissionModal } = useAdminStore();
  const {
    selectedEditItem,
    setSelectedEditItem,
    activeMainCategory,
    activeSubCategory,
    activeSubSubCategory,
  } = useCategoriesStore();
  const { selectedLanguage } = useLanguage();
  const { setToast } = useToastStore();
  const ref = useRef<HTMLDivElement>(null);
  const [showExternal, setShowExternal] = useState<boolean>(category.show_external);
  const [name, setName] = useState<string>(category.name);
  const [value, setValue] = useState<string>(category.name);
  const [brandHighlight, brandButton] = useToken('colors', ['brand.highlight', 'brand.button']);
  const { config } = useConfigStore();

  // Handle setting localized name
  useEffect(() => {
    if (selectedLanguage.abr === 'en') {
      setName(category.name);
      setValue(category.name);
    } else if (category.localizedNamesObj[selectedLanguage.abr]) {
      setName(category.localizedNamesObj[selectedLanguage.abr]);
      setValue(category.localizedNamesObj[selectedLanguage.abr]);
    }
  }, [selectedLanguage]);

  const showSuccessToast = () =>
    setToast({
      show: true,
      status: 'success',
      title: t('admin.connect.categoryUpdateSuccess'),
    });

  const showErrorToast = () =>
    setToast({
      show: true,
      status: 'error',
      title: t('admin.connect.categoryUpdateError'),
    });

  const showExternalMutation = useMutation({
    mutationFn: () => ConnectService.setShowExternal(category._id, category.name, true),
    onSuccess: () => {
      setShowExternal(!showExternal);
      showSuccessToast();
    },
    onError: () => showErrorToast(),
  });

  const hideExternalMutation = useMutation({
    mutationFn: () => ConnectService.hideExternal(category._id),
    onSuccess: () => {
      setShowExternal(!showExternal);
      showSuccessToast();
    },
    onError: () => showErrorToast(),
  });

  const partnerPermissionSave = async (item: Category) => {
    await connect.editItem.mutateAsync({
      itemId: item?._id,
      name: item?.name,
      localizedNames: item?.localized_names,
      partnerPermissions: item?.partner_permissions,
      level: item?.level,
    });
  };

  const handleEditItem = () => {
    if (selectedLanguage.abr === 'en') {
      connect.editItem
        .mutateAsync({
          itemId: category._id,
          name: value,
          localizedNames: category.localized_names,
          showExternal,
          level: category.level,
        })
        .then(() => setName(value));
    } else {
      category.localizedNamesObj[selectedLanguage.abr] = value;

      const str = Object.entries(category.localizedNamesObj)
        .map(([k, v]) => `${k}:${v}`)
        .join(',');

      connect.editItem
        .mutateAsync({
          itemId: category._id,
          name: category.name,
          localizedNames: str,
          showExternal,
          level: category.level,
        })
        .then(() => setName(value));
    }
  };

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      // Don't replace featured category
      if (dragIndex === hoverIndex || category.level === 10) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id: category._id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isDragDisabled && category.level !== 10,
  });

  drag(drop(ref));

  const setBackgroundColor = (cat: Category) => {
    if (
      activeMainCategory?._id === cat._id ||
      activeSubCategory?._id === cat._id ||
      activeSubSubCategory?._id === cat._id
    ) {
      return hexToRGBA(brandHighlight, 0.2);
    }

    return 'white';
  };

  return (
    <Box borderBottom="1px solid" borderBottomColor="rgba(178, 178, 178, .9)">
      <Flex
        ref={ref}
        data-handler-id={handlerId}
        paddingLeft={4}
        alignItems="center"
        backgroundColor={setBackgroundColor(category)}
        _hover={{ backgroundColor: hexToRGBA(brandHighlight, 0.05) }}
      >
        {!isDragDisabled && category.level !== 10 && (
          <DragHandleIcon marginRight={2} _hover={{ cursor: 'grab' }} />
        )}

        {selectedEditItem !== category && (
          <Flex width="full">
            <Flex
              paddingY={3}
              alignItems="center"
              flex={1}
              flexGrow={1}
              _hover={{ cursor: 'pointer' }}
              onClick={() => connect.handleSelectItem(category)}
            >
              <Text fontWeight="medium" fontSize="14px">
                {name}
              </Text>
            </Flex>

            <ButtonGroup color={brandButton} alignItems="center" isAttached>
              {showExternalOption && (
                <IconButtonWithTooltip
                  tooltipCopy={
                    showExternal
                      ? t('categoryInput.hide_external')
                      : t('categoryInput.show_external')
                  }
                  icon={showExternal ? <ViewOutline /> : <ViewSlash />}
                  ariaLabel={
                    showExternal
                      ? t('categoryInput.hide_external')
                      : t('categoryInput.show_external')
                  }
                  onClick={() =>
                    showExternal
                      ? hideExternalMutation.mutateAsync()
                      : showExternalMutation.mutateAsync()
                  }
                />
              )}

              <IconButtonWithTooltip
                tooltipCopy={t('global.actions.edit')}
                ariaLabel={t('global.actions.edit')}
                icon={<EditSimple />}
                onClick={() => {
                  setSelectedEditItem(category);
                }}
              />
            </ButtonGroup>
          </Flex>
        )}

        {selectedEditItem === category && (
          <Flex width="full">
            <Flex paddingY={3} alignItems="center" flex={1} flexGrow={1}>
              <Input
                variant="flushed"
                size="xs"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Flex>

            <ButtonGroup isAttached alignItems="center">
              <IconButtonWithTooltip
                tooltipCopy={t('global.action.saveChange')}
                ariaLabel={t('global.action.saveChange')}
                icon={<Check color="green" />}
                onClick={() => {
                  handleEditItem();
                }}
              />

              <IconButtonWithTooltip
                tooltipCopy={t('global.actions.cancel')}
                ariaLabel={t('global.actions.cancel')}
                icon={<Close color="red" />}
                onClick={() => {
                  setSelectedEditItem(null);
                  setValue(name);
                }}
              />

              {config.features.enable_partners && (
                <IconButtonWithTooltip
                  tooltipCopy={t('content.edit.partners.label')}
                  ariaLabel={t('content.edit.partners.label')}
                  icon={<People color="blue" />}
                  onClick={() => {
                    setPartnerPermissionModal({
                      isOpen: true,
                      value: category.partner_permissions || 'tenant',
                      selectedItem: category,
                      isControlled: false,
                      handleSubmit: partnerPermissionSave
                    });
                  }}
                />
              )}

              {category.level !== 10 && (
                <IconButtonWithTooltip
                  tooltipCopy={t('global.action.remove')}
                  ariaLabel={t('global.action.remove')}
                  icon={<Delete color="red" />}
                  onClick={() => {
                    setDeleteModal({
                      isOpen: true,
                      selectedItem: category,
                    });
                  }}
                />
              )}
            </ButtonGroup>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

ConnectCard.defaultProps = {
  showExternalOption: false,
  isDragDisabled: false,
};
