export const setCoverImageJustification = (imageJustification: string | undefined) => {
  if (imageJustification === 'left') {
    return 'left';
  }
  if (imageJustification === 'right') {
    return 'right';
  }

  return 'center';
};

export const getCardBackgroundUrl = (url: string | undefined) => {
  if (url) {
    return `https://res.cloudinary.com/continu/image/fetch/w_1450,h_2000,c_fit,f_auto,q_auto/${url}`;
  }
  return '/public/images/cardPlaceholder.png';
};

export const getWorkshopCardBackgroundUrl = (url: string | undefined) => {
  if (!url || url?.endsWith('q_auto/')) {
    return '/public/images/cardPlaceholder.png';
  }

  const split = url.split('.');
  const ext = split[split.length - 1];

  if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'svg') {
    return url;
  }

  return `https://res.cloudinary.com/continu/image/fetch/c_fill,w_700,h_375,f_auto,q_auto/${url}`;
}

export const getWorkshopCardImageAlignment = (coverJustification: string | undefined) => {
  if (coverJustification === 'left') {
    return 'top';
  }
  if (coverJustification === 'right') {
    return 'bottom';
  }
  return 'center'
};