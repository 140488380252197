import { create } from 'zustand';

interface UserState {
  avatarImageUrl: string;
  setAvatarImageUrl: (avatarImageUrl: string) => void;
}

export const useUserStore = create<UserState>((set) => ({
  avatarImageUrl: '',
  setAvatarImageUrl: (avatarImageUrl: string) => set({ avatarImageUrl }),
}));
