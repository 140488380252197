import { UseMutationResult, useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import { Category } from '@/client/types/Category';
import ConnectService from '@/client/services/api/admin/connect/ConnectService';
import { isEqual } from 'lodash';
import { useCategoriesStore } from '@/client/services/state/admin/categoriesStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { PartnerPermissions } from '@/client/types/Partner';

export interface UseConnectProps {
  addItem: UseMutationResult<any, unknown, any, unknown>;
  editItem: UseMutationResult<any, unknown, any, unknown>;
  items: Category[];
  setItems: React.Dispatch<React.SetStateAction<Category[]>>;
  subItems: Category[];
  setSubItems: React.Dispatch<React.SetStateAction<Category[]>>;
  subSubItems: Category[];
  setSubSubItems: React.Dispatch<React.SetStateAction<Category[]>>;
  searchTerm: string | null;
  setSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
  isSearching: boolean;
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>;
  searchItems: Category[];
  setSearchItems: React.Dispatch<React.SetStateAction<Category[]>>;
  searchStatus: string;
  searchFetchNextPage: () => void;
  searchIsFetchingNextPage: boolean;
  searchRefetch: () => void;
  levelOneStatus: string;
  levelOneFetchNextPage: () => void;
  levelOneIsFetchingNextPage: boolean;
  levelOneRefetch: () => void;
  levelTwoStatus: string;
  levelTwoFetchNextPage: () => void;
  levelTwoIsFetchingNextPage: boolean;
  levelTwoRefetch: () => void;
  levelThreeStatus: string;
  levelThreeFetchNextPage: () => void;
  levelThreeIsFetchingNextPage: boolean;
  levelThreeRefetch: () => void;
  deleteItem: UseMutationResult<any, unknown, Category | null, unknown>;
  fullTextSearchStatus: string;
  fullTextSearchFetchNextPage: () => void;
  fullTextSearchIsFetchingNextPage: boolean;
  fullTextSearchRefetch: () => void;
  updateSortOrder: UseMutationResult<any, unknown, any, unknown>;
  handleSelectItem: (item: Category) => void;
}

export const useConnect = (
  type: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade' | 'skill',
) => {
  const {
    activeMainCategory,
    activeSubCategory,
    setActiveMainCategory,
    setActiveSubCategory,
    setActiveSubSubCategory,
    setSelectedEditItem,
    selectedEditItem,
  } = useCategoriesStore();
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const [items, setItems] = useState<Category[]>([]);
  const [subItems, setSubItems] = useState<Category[]>([]);
  const [subSubItems, setSubSubItems] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchItems, setSearchItems] = useState<Category[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | 'oldest' | 'newest'>(
    type === 'category' ? 'oldest' : 'newest',
  );

  const previousItems = useRef<Category[]>(items);
  const previousSubItems = useRef<Category[]>(subItems);
  const previousSubSubItems = useRef<Category[]>(subSubItems);

  const checkIfUsingThreeColumnLayout = (): boolean =>
    type === 'location' || type === 'department' || type === 'category';

  useEffect(() => {
    if (!searchTerm) {
      setSubItems([]);
      setSubSubItems([]);
      setIsSearching(false);
      setActiveMainCategory(null);
      setActiveSubCategory(null);
      setActiveSubSubCategory(null);
    }

    if (searchTerm) {
      setIsSearching(true);
      setActiveMainCategory(null);
      setActiveSubCategory(null);
      setActiveSubSubCategory(null);
    }
  }, [searchTerm]);

  const {
    status: searchStatus,
    fetchNextPage: searchFetchNextPage,
    isFetchingNextPage: searchIsFetchingNextPage,
    refetch: searchRefetch,
  } = useInfiniteQuery({
    enabled: !!searchTerm && !checkIfUsingThreeColumnLayout(),
    queryKey: ['search', searchTerm, type, sortOrder],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.searchItems(searchTerm!, pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    onSuccess: (data) => {
      setSearchItems(data.pages.map((page) => page.data).flat());
    },
  });

  const {
    status: fullTextSearchStatus,
    fetchNextPage: fullTextSearchFetchNextPage,
    isFetchingNextPage: fullTextSearchIsFetchingNextPage,
    refetch: fullTextSearchRefetch,
  } = useInfiniteQuery({
    enabled: !!searchTerm && checkIfUsingThreeColumnLayout(),
    queryKey: ['full-text-search', searchTerm, type, sortOrder],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.fullTextSearch(pageParam, 500, searchTerm!, type),
    onSuccess: (data) => {
      const dataArr = data.pages.map((page) => page).flat();

      setItems(dataArr);
      setActiveMainCategory(dataArr[0]);

      setSubItems(dataArr[0].subItems || []);
      setActiveSubCategory(dataArr[0].subItems[0] || null);

      setSubSubItems(dataArr[0].subItems[0]?.subItems || []);
      setActiveSubSubCategory(dataArr[0].subItems[0]?.subItems[0] || null);
    },
  });

  const {
    status: levelOneStatus,
    fetchNextPage: levelOneFetchNextPage,
    isFetchingNextPage: levelOneIsFetchingNextPage,
    refetch: levelOneRefetch,
  } = useInfiniteQuery({
    enabled: !isSearching,
    queryKey: ['level-one', type, sortOrder],
    queryFn: ({ pageParam = 1 }) => ConnectService.getItems(pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setItems(data.pages.map((page) => page.data).flat());
    },
  });

  const {
    status: levelTwoStatus,
    fetchNextPage: levelTwoFetchNextPage,
    isFetchingNextPage: levelTwoIsFetchingNextPage,
    refetch: levelTwoRefetch,
  } = useInfiniteQuery({
    enabled: !!activeMainCategory && !isSearching,
    queryKey: ['level-two', activeMainCategory?._id, sortOrder, type],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.getSubItems(activeMainCategory?._id, pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setSubItems(data.pages.map((page) => page.data).flat());
    },
  });

  const {
    status: levelThreeStatus,
    fetchNextPage: levelThreeFetchNextPage,
    isFetchingNextPage: levelThreeIsFetchingNextPage,
    refetch: levelThreeRefetch,
  } = useInfiniteQuery({
    enabled: !!activeSubCategory && !isSearching,
    queryKey: ['level-three', activeSubCategory?._id, type],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.getSubSubItems(activeSubCategory?._id!, pageParam, type),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setSubSubItems(data.pages.map((page) => page.data).flat());
    },
  });

  interface AddItemVariables {
    level: number;
    name: string;
    parentId: string | null;
    itemType: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade' | 'skill';
    partnerPermissions?: PartnerPermissions;
  }

  const addItem = useMutation({
    mutationFn: ({ level, name, parentId, itemType, partnerPermissions }: AddItemVariables) =>
      ConnectService.addItem(level, name, parentId, itemType, partnerPermissions),
    onSuccess: (data, variables) => {
      if (variables.level === 1) {
        levelOneRefetch();
      } else if (variables.level === 2) {
        levelTwoRefetch();
      } else if (variables.level === 3) {
        levelThreeRefetch();
      }

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.createSuccess', { type: variables.itemType }),
      });
    },
    onError: (data, variables) => {
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.createError', { type: variables.itemType }),
      });
    },
  });

  interface EditItemVariables {
    itemId: string;
    name: string;
    localizedNames?: string;
    showExternal?: boolean;
    partnerPermissions?: PartnerPermissions;
    level: number;
  }

  const editItem = useMutation({
    mutationFn: (variables: EditItemVariables) =>
      ConnectService.editItem(
        variables.itemId,
        variables.name,
        variables.localizedNames,
        variables.showExternal,
        variables.partnerPermissions
      ),
    onSuccess: (data, variables) => {
      setSelectedEditItem(null);

      if (isSearching) {
        fullTextSearchRefetch();
      } else if (variables.level === 1) {
        levelOneRefetch();
      } else if (variables.level === 2) {
        levelTwoRefetch();
      } else if (variables.level === 3) {
        levelThreeRefetch();
      }

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.categoryUpdateSuccess'),
      });
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.categoryUpdateError'),
      });
    },
  });

  const handleRefetch = (level: number | undefined) => {
    if (isSearching) {
      searchRefetch();
      fullTextSearchRefetch();
    }

    if (type === 'category' || type === 'location' || type === 'department') {
      if (level === 3) {
        levelThreeRefetch();
      } else if (level === 2) {
        levelTwoRefetch();
        levelThreeRefetch();
      } else if (level === 1) {
        levelOneRefetch();
        levelTwoRefetch();
        levelThreeRefetch();
      }

      return;
    }

    if (type === 'team' || type === 'org_level' || type === 'grade') {
      levelOneRefetch();
    }
  };

  const deleteItem = useMutation({
    mutationFn: (category: Category | null) => ConnectService.deleteItem(category?._id),
    onSuccess: (data, category) => {
      handleRefetch(category?.level);

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.itemDeleteSuccessful'),
      });
    },
    onError: (error) => {
      console.log(error);
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.itemDeleteError'),
      });
    },
  });

  interface UpdateSortOrderVariables {
    ids: string[];
    parentId: string | null | undefined;
    level: number;
  }

  const updateSortOrder = useMutation({
    mutationFn: ({ ids, parentId, level }: UpdateSortOrderVariables) =>
      ConnectService.updateSortOrderIndex(ids, parentId, level),
  });

  // Handle drag and drop order updates to categories
  useEffect(() => {
    if (type !== 'category' || isSearching || items.length === 0) return;

    const areArraysEqual = isEqual(items, previousItems.current);

    if (!areArraysEqual && previousItems.current.length > 0) {
      updateSortOrder.mutate({
        ids: items.map((item) => item._id),
        parentId: null,
        level: 1,
      });
    }

    previousItems.current = items;
  }, [items]);

  useEffect(() => {
    if (type !== 'category' || isSearching || subItems.length === 0) return;

    const areArraysEqual = isEqual(subItems, previousSubItems.current);

    if (!areArraysEqual && previousSubItems.current.length > 0) {
      updateSortOrder.mutate({
        ids: subItems.map((item) => item._id),
        parentId: activeMainCategory?._id,
        level: 2,
      });
    }

    previousSubItems.current = subItems;
  }, [subItems]);

  useEffect(() => {
    if (type !== 'category' || isSearching || subSubItems.length === 0) return;

    const areArraysEqual = isEqual(subSubItems, previousSubSubItems.current);

    if (!areArraysEqual && previousSubSubItems.current.length > 0) {
      updateSortOrder.mutate({
        ids: subSubItems.map((item) => item._id),
        parentId: activeSubCategory?._id,
        level: 3,
      });
    }

    previousSubSubItems.current = subSubItems;
  }, [subSubItems]);

  const handleSelectSearchItem = (item: Category) => {
    if (item.level === 1 || item.level === 10) {
      setActiveMainCategory(item);

      if (item.subItems) {
        if (item.subItems.length === 0) {
          setSubItems([]);
          setActiveSubCategory(null);
          setSubSubItems([]);
          setActiveSubSubCategory(null);
          return;
        }

        const subItemOne = item.subItems[0];

        setSubItems(item.subItems || []);
        setActiveSubCategory(subItemOne || null);

        setSubSubItems(subItemOne.subItems || null);
        setActiveSubSubCategory(item.subItems[0].subItems[0] || null);
      }
    } else if (item.level === 2) {
      setActiveSubCategory(item);

      setSubSubItems(item.subItems || []);
      setActiveSubSubCategory(item.subItems[0] || null);
    } else if (item.level === 3) {
      setActiveSubSubCategory(item);
    }
  };

  const handleSelectNonSearchItem = (item: Category) => {
    if (item.level === 1 || item.level === 10) {
      setActiveMainCategory(item);

      setSubItems(item.subItems || []);
      setActiveSubCategory(null);
      setSubSubItems(item.subSubItems || []);
      setSubSubItems([]);

      setActiveSubSubCategory(null);
    } else if (item.level === 2) {
      setActiveSubCategory(item);
      setActiveSubSubCategory(null);
    } else if (item.level === 3) {
      setActiveSubSubCategory(item);
    }
  };

  const handleSelectItem = (item: Category) => {
    isSearching ? handleSelectSearchItem(item) : handleSelectNonSearchItem(item);
  };

  const connect = {
    addItem,
    editItem,
    items,
    setItems,
    subItems,
    setSubItems,
    subSubItems,
    setSubSubItems,
    searchTerm,
    setSearchTerm,
    isSearching,
    setIsSearching,
    searchItems,
    setSearchItems,
    searchStatus,
    levelOneStatus,
    levelOneFetchNextPage,
    levelOneIsFetchingNextPage,
    levelOneRefetch,
    levelTwoStatus,
    levelTwoFetchNextPage,
    levelTwoIsFetchingNextPage,
    levelTwoRefetch,
    levelThreeStatus,
    levelThreeFetchNextPage,
    levelThreeIsFetchingNextPage,
    levelThreeRefetch,
    searchFetchNextPage,
    searchIsFetchingNextPage,
    searchRefetch,
    deleteItem,
    sortOrder,
    setSortOrder,
    fullTextSearchStatus,
    fullTextSearchFetchNextPage,
    fullTextSearchIsFetchingNextPage,
    fullTextSearchRefetch,
    updateSortOrder,
    handleSelectItem,
  };

  return connect;
};
