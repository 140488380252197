import { Box, Circle, Icon, TagCloseButton, Tag, TagLabel, HStack } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import ProductsCreateTagList from '../../admin/input/ProductsCreateTagList';
import { Product, OrderingDirection } from '@/client/services/api/graphql/gql/graphql';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import { useQuery } from '@tanstack/react-query';
import { People, ShoppingBag } from '../../icons/ContinuIcons';

export function ProductLandingPageProductTags() {
  const { getValues, setValue, watch } = useFormContext();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  // list products
  const { data: selectedProducts, refetch: refetchSelectedProducts } = useQuery({
    queryKey: ['selected-products'],
    queryFn: () => ProductsService.listProducts(
      {
        id: { in: watch('products') },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }]
      }),
    enabled: !!watch('products')?.length
  });

  // search products
  const { data: searchedProducts } = useQuery({
    queryKey: ['searched-products', searchTerm],
    queryFn: () => ProductsService.listProducts(
      {
        title: { contains: searchTerm },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }]
      }),
    enabled: !!searchTerm?.length
  });


  const productSelected = (selectedItem: Product) => {
    const currentProducts = getValues('products') || [];
    currentProducts.push(selectedItem.id);
    setValue('products', currentProducts);

    refetchSelectedProducts();
  }

  return (
    <Box width="100%">
      <ProductsCreateTagList searchTerm={searchTerm} setSearchTerm={setSearchTerm} data={searchedProducts?.products} selectedItemChange={productSelected} />
      {!!selectedProducts?.products.length && (
        <HStack flexWrap="wrap">
          {selectedProducts.products.map((tag: Product) => (
            <Tag key={tag.id} variant="landingPage">
              <Circle
                backgroundColor='#E0E0E0'
                size="30px"
                marginRight={2}
              >
                <Icon
                  color='brand.legibleBlack.100'
                  // ToDo: Figure out desired Icon
                  as={ShoppingBag}
                />
              </Circle>
              <TagLabel>{tag.title}</TagLabel>

              <TagCloseButton
                onClick={() => {
                  setValue(
                    'products',
                    selectedProducts.products.filter((filterTag: Product) => filterTag.id !== tag.id).map((filteredTag: Product) => filteredTag.id),
                  );
                  refetchSelectedProducts();
                }}
              />
            </Tag>
          ))}
        </HStack>
      )}
    </Box>
  )
}