import { Accordion } from './components/accordion';
import { Avatar } from './components/avatar';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Drawer } from './components/drawer';
import { Heading } from './components/heading';
import { Input } from './components/input';
import { Link } from './components/link';
import { Menu } from './components/menu';
import { NumberInput } from './components/numberInput';
import { Progress } from './components/progress';
import { Radio } from './components/radio';
import { Select } from './components/select';
import { Spinner } from './components/spinner';
import { Switch } from './components/switch';
import { Table } from './components/table';
import { Tabs } from './components/tabs';
import { Tag } from './components/tag';
import { Text } from './components/text';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip';
import { colors } from './colors';
import { dividerTheme } from './components/divider';
import { extendTheme } from '@chakra-ui/react';
import { froalaStyles } from './global/froalaStyles';

const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
  '3xl': '120em', // 1920px
  '4xl': '160em', // 2560px
  '5xl': '192em', // 3072px
};

const customTheme = extendTheme({
  breakpoints,
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  colors,
  components: {
    Divider: dividerTheme,
    Accordion,
    Drawer,
    Spinner,
    Heading,
    Button,
    Tag,
    Progress,
    Input,
    NumberInput,
    Textarea,
    Radio,
    Checkbox,
    Select,
    Tooltip,
    Link,
    Menu,
    Switch,
    Avatar,
    Text,
    Tabs,
    Badge,
    Table,
  },
  styles: {
    global: {
      html: {
        scrollPaddingTop: '150px',
      },
      body: {
        color: '#1A1A1A',
      },
      h1: {
        fontSize: '2rem',
        lineHeight: '1.2857em',
        margin: '2rem 0',
        fontWeight: '500',
        padding: '0',
      },
      h2: {
        fontSize: '1.714rem',
        lineHeight: '1.2857em',
        margin: '2rem 0',
        fontWeight: '500',
        padding: '0',
      },
      h3: {
        fontSize: '1.429rem',
        lineHeight: '1.2857em',
        margin: '2rem 0',
        fontWeight: '500',
        padding: '0',
      },
      h4: {
        fontSize: '1.2rem',
        lineHeight: '1.2857em',
        margin: '2rem 0',
        fontWeight: '500',
        padding: '0',
      },
      h5: {
        fontSize: '1.143rem',
        lineHeight: '1.2857em',
        margin: '2rem 0',
        fontWeight: '500',
        padding: '0',
      },
      ...froalaStyles,
    },
  },
});

export default customTheme;
