// eslint-disable-next-line import/extensions
import contentFolderIcon from '@/client/assets/images/contentFolder.svg';
import { HStack, Text, Image, Stack, Divider, Box } from '@chakra-ui/react';
import typeMap from '@/client/utils/content/typeMap';
import { useFormContext } from 'react-hook-form';
import HeaderCloseIcon from '@/client/components/icons/HeaderCloseIcon';
import { useTranslation } from 'react-i18next';
import { ContentDetails, Product } from '@/client/services/api/graphql/gql/graphql';

export default function AuthoringContentList() {
  const { watch, setValue } = useFormContext<Product>();
  const { t } = useTranslation();

  const formName = 'contentListDetails';
  const selectedItems = watch(formName);

  if (selectedItems?.length === 0)
    return (
      <Box margin={4} textAlign="center">
        <Image src={contentFolderIcon} margin="auto" padding={4} />
        <Text variant="createLabel">{t('authoring.product.noSelectedContent')}</Text>
      </Box>
    );

  return (
    <Stack flexWrap="wrap">
      {/* different read/write properties on selected/fetched items */}
      {selectedItems?.map((item: ContentDetails) => (
        <Stack>
          <HStack alignItems="top">
            <Image
              src={item.image ? item.image : '/public/images/cardPlaceholder.png'}
              minWidth={150}
              width={150}
              height={75}
              borderRadius={10}
              position="relative"
              top={0}
              paddingTop={0}
              marginTop={0}
            />

            <Stack paddingX={4}>
              <Text variant="createLabel" color="brand.gold.100">
                {/* @ts-ignore */}
                {typeMap[item.type]}
              </Text>
              <Text variant="createLabel" overflowWrap="normal" wordBreak="break-word">{item.displayName}</Text>
            </Stack>
            <Box right="3em" position="absolute">
              <HeaderCloseIcon
                navigateAction={() =>
                  setValue(
                    formName,
                    // @ts-ignore
                    selectedItems.filter((i) => i.id !== item.id),
                  )
                }
              />
            </Box>
          </HStack>

          <Divider variant="create" />
        </Stack>
      ))}
    </Stack>
  );
}
