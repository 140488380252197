import { Flex, Grid, GridItem, Text, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useGetProduct } from '@/client/services/hooks/content/products/useGetProduct';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'usehooks-ts';
import EcommerceService from '@/client/services/api/graphql/EcommerceService';
import { useNavigate, useParams } from 'react-router-dom';
import { getPriceToSmallestCurrency } from '@/client/utils/getPriceToSmallestCurrency';
import { useToastStore } from '@/client/services/state/toastStore';
import { ProductLicensingType } from '@/client/services/api/graphql/gql/graphql';


export default function PaymentSetupSuccess() {
  useDocumentTitle('Manage Payment Methods');
  const { setToast } = useToastStore();
  const navigate = useNavigate();

  const { id: productId } = useParams();
  const { t } = useTranslation();

  const { data: productData } = useGetProduct(productId);

  const {
    data: paymentData,
  } = useQuery({
    queryKey: ['initiate-payment-method-setup'],
    queryFn: () => EcommerceService.getStripeAccountLastPaymentMethod(),
  });

  const submitPayment = useMutation({
    mutationFn: async () =>
      EcommerceService.submitOneTimePayment(
        paymentData,
        getPriceToSmallestCurrency(productData!.price!, productData!.currency!),
        productId!,
      ),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('ecommerce.submitPayment.success') });
      navigate('/explore');
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('ecommerce.submitPayment.error'),
      }),
  });

  const createSubscription = useMutation({
    mutationFn: async () =>
      EcommerceService.createSubscription(
        productId!
      ),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('ecommerce.submitPayment.success') });
      navigate('/explore');
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('ecommerce.submitPayment.error'),
      }),
  });
  
  
  useEffect(() => {
    if (paymentData && productData && productData.price) {
      if (productData.licensingType === ProductLicensingType.Subscription) {
        createSubscription.mutate();
      }
      if (productData.licensingType === ProductLicensingType.Permanent) {
        submitPayment.mutate();
      }
    }
  }, [paymentData, productData])

  return (
    <Flex direction="column" h="100%" paddingTop="100px" paddingX="170px">
      <Grid templateColumns="10% 1fr 1fr" templateRows="repeat(2, 1fr)" w="50%" margin="0 auto">
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={2}>
          <Spinner size="xl" color="brand.primary" />
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={1} rowSpan={1}>
          <Text fontSize="xl">{t('ecommerce.submitPayment.processing')}</Text>
        </GridItem>
        <GridItem colStart={2} colSpan={2} rowStart={2} rowSpan={1}>
          <Text fontSize="md">{t('ecommerce.submitPayment.dontClosePage')}</Text>
        </GridItem>
      </Grid>
    </Flex>
  );
}
