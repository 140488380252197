import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import SingleColumnLayout from '@/client/components/layout/admin/SingleColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useTranslation } from 'react-i18next';

export default function Levels() {
  const { t } = useTranslation();
  const connect = useConnect('org_level');

  const levelsPlural = t('admin.connect.levels.plural');
  const levelsSingular = t('admin.connect.levels.singular');

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: levelsSingular })}
        showSortSelector
        title={levelsPlural}
        setSearchTerm={connect.setSearchTerm}
        sortOrder={connect.sortOrder}
        setSortOrder={connect.setSortOrder}
        isSearching={connect.isSearching}
      />

      <SingleColumnLayout
        type="org_level"
        placeholderType={levelsSingular}
        noSearchResultsText={t('admin.connect.noSearchResultsMain', { type: levelsPlural })}
        connect={connect}
      />

      <DeleteItemModal deleteItem={connect.deleteItem} />
    </>
  );
}
