/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

interface CreateInputProps {
  name: string;
  variant: 'createTitle' | 'create';
  placeholder: string;
  isRequired?: boolean;
  type?: 'text' | 'number';
}

export default function CreateInput({
  name,
  variant,
  placeholder,
  isRequired,
  type,
}: CreateInputProps) {
  const { control, formState } = useFormContext();

  const { errors } = formState;
  const hasError = !!errors[name];
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: isRequired || false, message: t('authoring.fieldRequired') },
      }}
      render={({ field }) => (
        <FormControl isInvalid={hasError}>
          <InputGroup>
            <Input
              type={type}
              {...field}
              variant={variant}
              placeholder={placeholder}
              isRequired={isRequired}
              wordBreak="break-word"
            />

            {variant === 'createTitle' && !field.value && !hasError && (
              <InputRightElement width="fit-content" marginTop={2}>
                <Text fontSize="xs" fontWeight="600" color="brand.legibleBlack.10">
                  {t('authoring.generalDetails.titleRequiredDenotation')}
                </Text>
              </InputRightElement>
            )}

            {hasError && errors[name]?.message && (
              <InputRightElement width="fit-content" marginRight={4}>
                <Text fontSize="xs" color="red">
                  {errors[name]?.message as string}
                </Text>
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
      )}
    />
  );
}

CreateInput.defaultProps = {
  isRequired: false,
  type: 'text',
};
