import { Alert, AlertIcon, AlertTitle, Center, Link } from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';

interface ErrorAlertProps {
  title: string;
  backAction?: {
    to: string;
    label: string;
  };
}

export default function ErrorAlert({ title, backAction }: ErrorAlertProps) {
  return (
    <Center>
      <Alert status="error" maxWidth="container.sm" marginTop={8}>
        <AlertIcon />

        <AlertTitle mr={2}>{title}</AlertTitle>

        {backAction && (
          <Link as={RouterLink} to={backAction.to}>
            {backAction.label}
          </Link>
        )}

        {!backAction && (
          <Link as={RouterLink} to="/explore">
            Back to explore
          </Link>
        )}
      </Alert>
    </Center>
  );
}

ErrorAlert.defaultProps = {
  backAction: null,
};
