import CreateTabs from '@/client/components/admin/create/shared/tabs/CreateTabs';
import DiscardModal from '@/client/components/admin/create/shared/overlay/DiscardModal';
import TabControls from '@/client/components/admin/create/content/tabs/TabControls';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductGeneralDetailsForm from './ProductGeneralDetailsForm';
import ProductAccessForm from './ProductAccessForm';
import ProductSettingsForm from './ProductSettingsForm';
import ProductPublishForm from './ProductPublishForm';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import PublishModal from '../PublishModal';
import CreateDeleteButton from '../../create/shared/buttons/CreateDeleteButton';
import CreateSaveAndExitButton from '../../create/shared/buttons/CreateSaveAndExitButton';
import { Product } from '@/client/services/api/graphql/gql/graphql';
// import NoImageModal from '@/client/components/admin/create/content/overlay/NoImageModal';

interface EditProductFormProps {
  saveFn: () => Promise<void>;
  publishFn: () => Promise<void>;
}

export default function EditProductForm({ saveFn, publishFn }: EditProductFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { watch, formState } = useFormContext<Product>();
  const { isValid } = formState;
  const { tabIndex, setTabIndex, publishModalIsOpen, setPublishModalIsOpen } = useCreateStore();

  const title = watch('title');
  const description = watch('description');

  const canAdvance = !!(title && description);

  const tabOptions = [
    {
      label: t('authoring.product.label'),
      content: <ProductGeneralDetailsForm canAdvance={canAdvance} />,
      isDisabled: false,
    },
    {
      label: t('authoring.tabs.addContent'),
      content: <ProductAccessForm canAdvance={canAdvance} />,
      isDisabled: !isValid || !canAdvance,
    },
    {
      label: t('authoring.tabs.settings'),
      content: <ProductSettingsForm />,
      isDisabled: !isValid || !canAdvance,
    },
    {
      label: t('authoring.tabs.publish'),
      content: <ProductPublishForm />,
      isDisabled: !isValid || !canAdvance,
    },
  ];

  const buttonOptions = [
    <CreateDeleteButton onClick={() => console.log('Delete button placeholder')} />,
    <CreateSaveAndExitButton
      onClick={async () => {
        await saveFn();
        navigate('/overview/products');
      }}
    />,
  ];

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <>
          <TabControls
            buttonOptions={buttonOptions}
            canAdvance={canAdvance}
            tabOptionsLength={tabOptions.length}
            onProceed={() => {
              // autosave
              saveFn();
              setTabIndex(tabIndex + 1);
            }}
          />

          <DiscardModal onDiscard={() => navigate('/overview/products')} />

          {/* <NoImageModal
            onProceed={() => {
              setTabIndex(tabIndex + 1);
              setNoImageModalIsOpen(false);
            }}
          /> */}

          {publishModalIsOpen && (
            <PublishModal
              onPublish={async () => {
                await publishFn();
                setPublishModalIsOpen(false);
              }}
              onClose={() => setPublishModalIsOpen(false)}
            />
          )}
        </>
      }
    />
  );
}
