import { Box, Button, Input, Text, Textarea, VStack } from '@chakra-ui/react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { FormProvider, SubmitHandler, useForm, useFormContext, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import RegistrationFormsService from '@/client/services/api/RegistrationFormsService';
import { CreateFormBody, Form, RegistrationFormType } from '@/client/types/RegistrationForms';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { ProductLandingPageProductTags } from '@/client/components/forms/registration-forms/ProductLandingPageProductTags';

type DetailsProps = {
  onNextButtonClick: () => void
}

export function ProductLandingPageDetails({ onNextButtonClick }: DetailsProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Box>
      <Text fontWeight="500" mb={4}>{t("ecommerce.landingPageCreate.header.productLandingPage")}</Text>
      <VStack alignItems="start">
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.name')} />
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.name')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.headline')} />
        <Controller
          control={control}
          name="headline"
          render={({ field: { onChange, value } }) => (
            <Input type="text" onChange={onChange} value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.headline')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.description')} />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Textarea onChange={onChange} value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.description')}`}
              variant="landingPage"
              height="140px"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.productsListed')} />
        <ProductLandingPageProductTags />
        <Button variant="adminBrandPrimary" borderRadius={6} marginTop={6} alignSelf="flex-end" onClick={() => onNextButtonClick()}>Save & Continue</Button>
      </VStack>
    </Box>
  )
}


export default function ProductLandingPage() {
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const routeParams = useParams();

  if (routeParams.id) {
    useDocumentTitle('Edit Landing Page');
  } else {
    useDocumentTitle('Create Landing Page');
  }

  const defaultFormValues: {
    type: RegistrationFormType | undefined
  } = {
    type: 'product_landing_page'
  };

  const methods = useForm<Form>({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const { reset } = methods;

  const { data: landingPageData } = useQuery({
    queryKey: ['registration-forms', routeParams.id],
    queryFn: () => RegistrationFormsService.getForm(routeParams.id!),
    enabled: !!routeParams.id,
  });

  const nextPage = () => {
    //TODO: Add Nav to next page once reg forms are ready
  };

  const updatePage = useMutation({
    mutationFn: (data: Form) => RegistrationFormsService.updateForm(routeParams.id!, data),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('registrationForms.publish_success'),
      });
    },
    onError: (error: Record<string, any>) => {
      console.error('error: ', error);

      const errorMessage = t('registrationForms.publish_error');

      setToast({
        show: true,
        status: 'error',
        title: errorMessage,
      });
    },
  });

  const createPage = useMutation({
    mutationFn: (data: CreateFormBody) => RegistrationFormsService.createForm(data),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('registrationForms.publish_success'),
      });
    },
    onError: (error: Record<string, any>) => {
      console.error('error: ', error);

      const errorMessage = t('registrationForms.publish_error');

      setToast({
        show: true,
        status: 'error',
        title: errorMessage,
      });
    },
  });

  useEffect(() => {
    if (!landingPageData) return;

    reset(landingPageData);
  }, [landingPageData]);

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const pageBody = {
      ...data,
    };

    // TODO: Add onSubmit to new reg forms after refactor
    // routeParams.id ? updatePage.mutateAsync(pageBody) : createPage.mutateAsync(pageBody);
  };

  return (
    <Box margin="3% 5%">
      <Text fontSize="xl" lineHeight="1.2" fontWeight="500" marginBottom={2}>
        {t('ecommerce.landingPageCreate.header.createLandingPage')}
      </Text>
      <Text fontSize="sm" lineHeight="1.2" fontWeight="300" marginBottom={8}>
        {t('ecommerce.landingPageCreate.header.describeCreateLandingPage')}
      </Text>
      <FormProvider {...methods}>
        <ProductLandingPageDetails onNextButtonClick={nextPage} />
        {/* <RegistrationFormsEditor /> */}
      </FormProvider>
    </Box>
  );
}
