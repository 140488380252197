import { ArrowRight, Check } from '@/client/components/icons/ContinuIcons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
  Progress,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import { Instructor, LilStructure } from '../../types/content/ImportedContent';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import CommentList from '../../components/lists/CommentList';
import ContentHeader from '../../components/layout/ContentHeader';
import ContentLength from '../../components/data-display/content/ContentLength';
import ContentService from '@/client/services/api/ContentService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '../../components/html/HtmlRenderer';
import ImportedContentService from '@/client/services/api/ImportedContentService';
import Loading from '@/client/components/media/Loading';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import ViewActions from '@/client/components/overlay/ViewActions';
import { capCase } from '@/client/utils/capCase';
import { minutesToSeconds } from 'date-fns';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useParams } from 'react-router-dom';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface ImportedContentProps {
  inJourney?: boolean;
  journeyId?: string;
  importedContentId?: string;
}

export default function ImportedContent({
  inJourney,
  journeyId,
  importedContentId,
}: ImportedContentProps) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { sendSessionTime } = useSessionTracking(id, 'imported_content');
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { allowView } = useCheckContentAuth();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const { partner } = usePartnerStore();
  const [selectedInstructor, setSelectedInstructor] = useState<number>(0);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const brandPrimary = useToken('colors', 'brand.primary');
  const heroRef = useRef<HTMLDivElement>(null);

  const contentId = importedContentId || id;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return () => {
      sendSessionTime();
    };
  }, []);

  useEffect(() => {
    if (isAllowed) {
      AnalyzeService.postViews(contentId, 'imported_content');
      ContentService.postViews(contentId, 'imported_content');
    }
  }, [isAllowed]);

  const { isError, isLoading, data } = useQuery({
    enabled: !!contentId,
    queryKey: ['imported-content', contentId, user._id],
    queryFn: () => ImportedContentService.getImportedContent(contentId, user._id),
    onSuccess: (contentData) => {
      checkShowPopupSurvey(contentData.content, contentData.progressPercent === 100);

      trackEvent('imported_content_viewed', {
        content: contentData.content._id,
        content_type: 'imported_content',
        title: contentData.content.title,
      });

      if (inJourney && contentData.progressPercent === 100) {
        const contexts = [{ key: 'journey', value: journeyId }];

        ContentService.completeContent(contentId, 'imported_content', contexts);
      }
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('modules.notifications.unableToGetArticle'),
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.content);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  const register = useMutation({
    mutationFn: () => ImportedContentService.register(contentId, user._id),
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: 'Unable to create registration.',
      });
    },
  });

  const formatDifficulty = (difficulty: string) => {
    const lowercase = difficulty.toLowerCase();

    return capCase(lowercase);
  };

  const setInstructorUrl = (instructorUrl: string, vendor: string) => {
    switch (vendor) {
      case 'linkedin_learning':
        return `https://www.linkedin.com/in${instructorUrl}`;
      case 'udemy':
        return `https://www.udemy.com${instructorUrl}`;
      default:
        return '';
    }
  };

  const setVendorImage = (vendor: string) => {
    switch (vendor) {
      case 'linkedin_learning':
        return 'url(https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/linkedin-full.png)';
      case 'udemy':
        return 'url(https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/udemy_full.svg)';
      case 'masterclass':
        return 'url(https://assets.recogmedia.net/1/121/Entry_Uploads/207279-thumb.jpg)';
      default:
        return '';
    }
  };

  useDocumentTitle(data ? data.content.title : 'Imported Content', true);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get imported content" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <>
      {isAllowed && (
        <>
          <Flex
            width="full"
            background={`url(${data.content.banner_image})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            ref={heroRef}
          >
            <Box width="full" height="full" bgColor="blackAlpha.700">
              {!inJourney && (
                <ContentHeader
                  contentType="imported_content"
                  contentTitle={data.content.title}
                  contentId={contentId}
                  allowComments={data.content.allow_comments}
                  goToComments={() => setActiveTabIndex(1)}
                  heroRef={heroRef}
                />
              )}

              <Center width="full" marginY={40}>
                <VStack spacing={6}>
                  <Text variant="heroheader" color="white" marginY="0" marginBottom={-2}>
                    {data.content.title}
                  </Text>

                  {data.content.duration && (
                    <ContentLength
                      // duration={minutesToSeconds(Math.round(data.content.duration / 60 / 60) * 60)}
                      duration={data.content.duration}
                      color="white"
                      size="lg"
                    />
                  )}

                  <Flex alignItems="center">
                    <Box color="white" marginRight={4}>
                      {data.progressPercent}% {t('contentViews.tracks.complete')}
                    </Box>

                    <Progress value={data.progressPercent} variant="hollow" />
                  </Flex>

                  <LinkBox>
                    <Button
                      rightIcon={<ArrowRight />}
                      paddingX={8}
                      paddingY={7}
                      onClick={!data.registration ? () => register.mutate() : () => {}}
                    >
                      <LinkOverlay isExternal href={data.content.vendor_full_link}>
                        {t('importedContent.view_course')}
                      </LinkOverlay>
                    </Button>
                  </LinkBox>
                </VStack>
              </Center>
            </Box>
          </Flex>

          <Center
            width={{ base: '100%', md: '70%' }}
            margin="0 auto"
            marginTop={8}
            marginBottom={24}
          >
            <Tabs
              index={activeTabIndex}
              width="full"
              isFitted
              onChange={(index) => setActiveTabIndex(index)}
            >
              <TabList
                marginBottom={8}
                alignContent="center"
                display="flex"
                justifyContent="center"
              >
                <Tab flex="0" marginRight={4}>
                  {t('manage.info')}
                </Tab>
                {!partner._id && <Tab flex="0">{t('importedContent.discussion')}</Tab>}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
                    <Box width={{ base: '100%', lg: '50%' }}>
                      {data.content.learn_items.length > 0 && (
                        <Box marginBottom={8}>
                          <Text as="h4">{t('importedContent.in_this_you_will_learn')}</Text>

                          {data.content.learn_items.map((item: any) => {
                            if (item === '') return;

                            return (
                              <HStack key={item} spacing={4}>
                                <Check color="brand.primary" />

                                <Text>{item}</Text>
                              </HStack>
                            );
                          })}
                        </Box>
                      )}

                      <Text as="h4">{t('importedContent.description')}</Text>

                      <HtmlRenderer html={data.content.description} />

                      {data.content.lil_structure && data.content.lil_structure.length > 0 && (
                        <>
                          <Text as="h4">{t('importedContent.course_outline')}</Text>

                          <List>
                            {data.content.lil_structure.map((item: LilStructure) => (
                              <ListItem
                                key={item.title}
                                borderBottom="1px"
                                borderBottomColor="gray.200"
                                paddingY={2}
                              >
                                {item.title}
                              </ListItem>
                            ))}
                          </List>
                        </>
                      )}
                    </Box>

                    <Flex
                      width={{ base: '100%', lg: '50%' }}
                      justifyContent={{ base: 'center', lg: 'flex-end' }}
                    >
                      {data.content.instructors.length === 1 && (
                        <Box
                          backgroundColor="blackAlpha.50"
                          width="70%"
                          marginBottom={10}
                          paddingX={6}
                        >
                          <Stack>
                            <Box>
                              <Text as="h4" marginBottom={0}>
                                {t('importedContent.author')}
                              </Text>
                              <Text>{data.content.instructors[0].full_name}</Text>
                            </Box>

                            {data.content.difficulty && data.content.vendor === 'linkedin_learning' && (
                              <Box>
                                <Text as="h4" marginBottom={0}>
                                  {t('importedContent.difficulty')}
                                </Text>
                                <Text>{formatDifficulty(data.content.difficulty)}</Text>
                              </Box>
                            )}

                            <Box>
                              <Text as="h4" marginBottom={0}>
                                Provider
                              </Text>

                              <Box
                                height="50px"
                                width="80px"
                                backgroundImage={setVendorImage(data.content.vendor)}
                                backgroundSize="80px auto"
                                backgroundPosition="center"
                                transform="scale(1)"
                                backgroundRepeat="no-repeat"
                              />
                            </Box>
                          </Stack>
                        </Box>
                      )}

                      {data.content.instructors.length > 1 && (
                        <Box width="70%" marginBottom={10}>
                          <VStack
                            spacing={0}
                            backgroundColor="blackAlpha.50"
                            paddingY={16}
                            paddingX={6}
                          >
                            {data.content.instructors.map(
                              (instructor: Instructor, index: number) => {
                                if (index !== selectedInstructor) return;

                                return (
                                  <VStack spacing={2} key={instructor.full_name}>
                                    <Avatar
                                      name={instructor.full_name}
                                      src={instructor.image}
                                      marginBottom={4}
                                    />
                                    <Link
                                      isExternal
                                      href={
                                        instructor.url
                                          ? setInstructorUrl(instructor.url, data.content.vendor)
                                          : '#'
                                      }
                                      fontWeight="bold"
                                      display="block"
                                      marginTop={0}
                                    >
                                      {instructor.full_name}
                                    </Link>

                                    {instructor.job_title && (
                                      <Text textAlign="center" fontSize="sm" marginTop={20}>
                                        {instructor.job_title}
                                      </Text>
                                    )}

                                    {instructor.bio && <HtmlRenderer html={instructor.bio} />}
                                  </VStack>
                                );
                              },
                            )}

                            <HStack padding={4}>
                              {data.content.instructors.map(
                                (instructor: Instructor, index: number) => (
                                  <Box
                                    key={instructor._id}
                                    width={2.5}
                                    height={2.5}
                                    boxShadow={`inset 0 0 0 2px ${brandPrimary}`}
                                    background={
                                      selectedInstructor === index ? 'brand.primary' : 'transparent'
                                    }
                                    display="inline-block"
                                    borderRadius={8}
                                    transition="0.3s ease all"
                                    onClick={() => {
                                      setSelectedInstructor(index);
                                    }}
                                    _hover={{
                                      cursor: 'pointer',
                                      boxShadow: `inset 0 0 0 4px ${brandPrimary}`,
                                    }}
                                  />
                                ),
                              )}
                            </HStack>
                          </VStack>
                          <Box>
                            <Text as="h4" fontSize="xs" marginBottom={0}>
                              Provider
                            </Text>

                            <Box
                              height="50px"
                              width="80px"
                              backgroundImage={setVendorImage(data.content.vendor)}
                              backgroundSize="80px auto"
                              backgroundPosition="center"
                              transform="scale(1)"
                              backgroundRepeat="no-repeat"
                            />
                          </Box>
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </TabPanel>

                <TabPanel maxWidth="668px" margin="0 auto">
                  {data.content.allow_comments && !partner._id && (
                    <CommentList
                      contentId={contentId}
                      contentType="imported_content"
                      contentTitle={data.content.title}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Center>
          <ViewActions buttons={['gototop']} bottomOffset={showPopup ? 10 : 0} />

          {/* TODO: Only show when completed */}
          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.content.surveys[0].survey_id}
              contentId={data.content._id}
              contentType="imported_content"
            />
          )}
        </>
      )}
    </>
  );
}

ImportedContent.defaultProps = {
  inJourney: false,
  journeyId: null,
  importedContentId: null,
};
