import { Course, LearningTrack } from '@/client/types/content/learning-tracks/LearningTrack';

import ContentService from './ContentService';
import RatingService from './RatingService';
import { UserTrack } from '@/client/types/content/learning-tracks/UserTrack';
import { analyzeApiClient } from './clients/analyzeApiClient';
import { formatTrack } from '@/client/utils/content/learning-track/learningTrack';
import { learnApiClient } from './clients/learnApiClient';
import { setContentType } from '@/client/utils/content/setContentType';

const getUserRating = async (contentId: string | undefined, userId: string) => {
  const response = await RatingService.getUserContentRating(contentId, userId);

  return response;
};

const getUserCompletedTracks = async (contextId: string | undefined): Promise<UserTrack> => {
  const response = await learnApiClient.get(
    `user-tracks/${contextId}?fields=track,user,completed_articles,completed_media,completed_quizzes,completed_assessments,completed_scorm,completed_date,last_viewed,viewed_certificate`,
  );

  return response.data;
};

// Sends track progress to analyze
const markTrackProgress = async (
  percentComplete: number,
  status: string,
  trackId: string | undefined,
) => {
  const response = await analyzeApiClient.post(`tracks`, {
    percentage: percentComplete,
    status,
    track: trackId,
  });

  return response.data;
};

const getLearningTrack = async (trackId: string) => {
  const learningTrackResponse = await learnApiClient.get(
    `tracks/${trackId}?fields=name,user,draft,explore_hide,description,category,topic,creator_name,creator_email,creator_bio,creator_image,creator_link,creator_notes,creator_linkedin,creator_facebook,creator_twitter,learn_items,images,duration,quiz,sequential,tags,sections,linked_category,banner_image,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,resource,certificate,certificate_data,rating_configuration,average_rating,allow_comments,lock_scorm,groups,surveys,metadata&sections.courses.article-populate=title,duration,type&sections.courses.assessment-populate=title,duration,type,video_assessment&sections.courses.file-populate=title,duration,type&sections.courses.quiz-populate=title,duration,type&sections.courses.scorm-populate=title,duration,type&sections.courses.video-populate=title,duration,type`,
  );

  return learningTrackResponse.data;
};

const getLearningTrackInUserContext = async (
  trackId: string | undefined,
  userId: string,
  contextId: string | undefined,
): Promise<{
  learningTrack: LearningTrack;
  formattedTrack: any;
  courseList: Course[];
  trackDuration: any;
  trackProgress: any;
  nextAvailableContent: any;
  sections: any;
  hasCompleted: boolean;
  lastViewedContent: any;
  completedDate: string;
  userTrack: UserTrack | undefined;
  userRating: any[];
  resource: boolean;
}> => {
  const learningTrackResponse = await learnApiClient.get(
    `tracks/${trackId}?fields=name,user,draft,explore_hide,description,category,topic,creator_email,creator_name,creator_bio,creator_image,creator_link,creator_notes,creator_linkedin,creator_facebook,creator_twitter,learn_items,images,duration,quiz,sequential,tags,sections,linked_category,banner_image,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,resource,certificate,certificate_data,rating_configuration,average_rating,allow_comments,lock_scorm,groups,surveys,metadata&sections.courses.article-populate=title,duration,type&sections.courses.assessment-populate=title,duration,type,video_assessment&sections.courses.file-populate=title,duration,type&sections.courses.quiz-populate=title,duration,type&sections.courses.scorm-populate=title,duration,type&sections.courses.video-populate=title,duration,type`,
  );

  const userTrack = await getUserCompletedTracks(contextId);

  const userRatingResponse = await getUserRating(trackId, userId);

  const formattedTrack = formatTrack(
    learningTrackResponse.data.sections,
    userTrack,
    learningTrackResponse.data.sequential,
  );

  const courseList = formattedTrack.sections.map((section) => section.courses).flat();

  let completedDate = '';
  let hasCompleted = false;

  const lastViewed = courseList.find((course) => course.contentId === userTrack.last_viewed);

  if (userTrack.completed_date) {
    completedDate = userTrack.completed_date;
    hasCompleted = true;
  }

  if (formattedTrack.trackProgress > 0)
    markTrackProgress(formattedTrack.trackProgress, 'progress', trackId);

  return {
    learningTrack: learningTrackResponse.data,
    formattedTrack,
    courseList,
    trackDuration: formattedTrack.totalDuration,
    trackProgress: formattedTrack.trackProgress,
    nextAvailableContent: formattedTrack.nextAvailableContent,
    sections: formattedTrack.sections,
    hasCompleted,
    completedDate,
    lastViewedContent: lastViewed,
    userTrack,
    userRating: userRatingResponse,
    resource: learningTrackResponse.data.resource,
  };
};

const setUserTrackLastViewed = async (
  userTrackId: string | undefined,
  contentId: string | undefined,
) => {
  const response = await learnApiClient.post(`user-tracks/${userTrackId}/lastview/${contentId}`);

  return response.data;
};

// Marks content within a track as complete in User Track
const markTrackContentComplete = async (
  userTrackId: string | undefined,
  contentType: string | undefined,
  contentId: string | undefined,
) => {
  const type = setContentType(contentType);

  const userTrackResponse = await learnApiClient.post(
    `user-tracks/${userTrackId}/complete/${type}/${contentId}?fields=track,user,completed_articles,completed_media,completed_quizzes,completed_assessments,completed_scorm,completed_date,last_viewed`,
  );

  const contexts = [{ key: 'track', value: userTrackResponse.data.track }];
  await ContentService.completeContent(contentId, contentType, contexts);

  return userTrackResponse;
};

// Marks track complete via assignments
const markTrackComplete = async (contentId: string | undefined, contentType: string) => {
  const assignmentsResponse = await learnApiClient.post(`assignments/complete-item`, {
    content: contentId,
    type: contentType,
  });

  return assignmentsResponse.data;
};

const createUserTrack = async (trackId: string | undefined, userId: string) => {
  const response = await learnApiClient.post(`user-tracks`, {
    user: userId,
    track: trackId,
    completed_media: [],
    completed_articles: [],
    completed_quizzes: [],
    completed_assessments: [],
    completed_scorm: [],
  });

  return response.data;
};

const postUserTrackCompletedDate = async (userTrackId: string | undefined) => {
  const response = await learnApiClient.post(
    `user-tracks/${userTrackId}?fields=track,user,completed_articles,completed_media,completed_quizzes,completed_assessments,completed_scorm,completed_date,last_viewed`,
    {
      completed_date: new Date(),
    },
  );

  return response.data;
};

const markCertificateViewed = async (userTrackId: string | undefined) => {
  const response = await learnApiClient.post(
    `user-tracks/${userTrackId}?fields=track,user,completed_articles,completed_media,completed_quizzes,completed_assessments,completed_scorm,completed_date,last_viewed,viewed_certificate`,
    {
      viewed_certificate: true,
    },
  );

  return response.data;
};

const TrackService = {
  getLearningTrack,
  getLearningTrackInUserContext,
  getUserCompletedTracks,
  setUserTrackLastViewed,
  markTrackContentComplete,
  createUserTrack,
  markCertificateViewed,
  postUserTrackCompletedDate,
  markTrackProgress,
  markTrackComplete,
};

export default TrackService;
