import { learnV4ApiClient } from '../../clients/learnV4ApiClient';

const search = async (
  searchTerm: string,
  types: string,
  contentOnly: boolean | undefined,
  includeJourneys: boolean | undefined,
) => {
  // TODO: If !contentOnly, then we need to search for categories as well

  const response = await learnV4ApiClient.get(`admin-content/analytics`, {
    params: {
      $or: `10,title,$regex,2,${searchTerm},i,name,$regex,2,${searchTerm},i`,
      page: '1',
      per_page: '6',
      types,
    },
  });

  const content = response.data.data;

  const contentToSearch = [
    {
      title: 'Articles',
      options: content
        .filter((item: any) => item.type === 'article')
        .map((item: any) => ({ ...item, filterType: 'Article' })),
    },
    {
      title: 'Files',
      options: content
        .filter((item: any) => item.type === 'file')
        .map((item: any) => ({ ...item, filterType: 'Media' })),
    },
    {
      title: 'Videos',
      options: content
        .filter((item: any) => item.type === 'video')
        .map((item: any) => ({ ...item, filterType: 'Media' })),
    },
    {
      title: 'Scorm',
      options: content
        .filter((item: any) => item.type === 'scorm')
        .map((item: any) => ({ ...item, filterType: 'ScormContent' })),
    },
    {
      title: 'Imported Content',
      options: content
        .filter((item: any) => item.type === 'imported_content')
        .map((item: any) => ({ ...item, filterType: 'ImportedContent' })),
    },
    {
      title: 'Learning Tracks',
      options: content
        .filter((item: any) => item.type === 'track')
        .map((item: any) => ({ ...item, filterType: 'Track' })),
    },
    {
      title: 'Workshops',
      options: content
        .filter((item: any) => item.type === 'workshop' || item.type === 'multi_session_workshop')
        .map((item: any) => ({ ...item, filterType: 'Workshop' })),
    },
    {
      title: 'Assessments',
      options: content
        .filter((item: any) => item.type === 'assessment')
        .map((item: any) => ({ ...item, filterType: 'Assessment' })),
    },
  ];

  if (includeJourneys) {
    contentToSearch.push({
      title: 'Journeys',
      options: content
        .filter((item: any) => item.type === 'journey')
        .map((item: any) => ({ ...item, filterType: 'Journey' })),
    });
  }
  return contentToSearch;
};

const ContentSegmentationSearchService = {
  search,
};

export default ContentSegmentationSearchService;
