import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { Assignment } from '@/client/types/Assignment';
import DashboardCard from '@/client/components/data-display/cards/DashboardCard';
import PlaceholderImage from '@/client/components/media/images/PlaceholderImage';
import ToggleButton from '@/client/components/buttons/ToggleButton';
import { assign } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';

interface DashboardAssignmentsListProps {
  completedAssignments: Assignment[];
  openAssignments: Assignment[];
}

export default function DashboardAssignmentsList({
  completedAssignments,
  openAssignments,
}: DashboardAssignmentsListProps) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [assignments, setAssignments] = useState(openAssignments);

  useEffect(() => {
    if (isChecked) {
      setAssignments(completedAssignments);
    } else {
      setAssignments(openAssignments);
    }
  }, [isChecked]);

  return (
    <>
      <Flex alignItems="center" justifyContent="right">
        <ToggleButton
          leftLabel={t('dashboard.assignments.openAssignments')}
          rightLabel={t('dashboard.assignments.completed')}
          checked={isChecked}
          setIsChecked={setIsChecked}
        />
      </Flex>

      {!assignments.length && (
        <Box padding={10} textAlign="center" fontSize="sm" color="gray.400">
          <PlaceholderImage name="house" />

          {isChecked ? (
            <Text>{t('dashboard.assignments.hasNoCompleted')}</Text>
          ) : (
            <Trans i18nKey="dashboard.assignments.hasNoAssigned" />
          )}
        </Box>
      )}

      {assignments.length > 0 && (
        <Stack spacing={4}>
          {assignments.map((assignment: Assignment, index: number) => (
            <Box
              key={assignment._id}
              borderBottom={index < assignments.length - 1 ? '1px solid gray' : ''}
              borderColor="gray.300"
              paddingBottom={2}
            >
              {assignment.assigned_content.map((assignedContent) => {
                // TODO: Why aren't archived assignments filtered out at the API level?
                if (!assignedContent.content_id || assignedContent.content_id.archived) return;

                let hasCompleted = false;

                if (assignment.completed_content.length && !isChecked) {
                  hasCompleted = assignment.completed_content.some(
                    (completedContent) =>
                      completedContent.content_id === assignedContent.content_id._id,
                  );
                }

                const getDays = (dueDate: string) =>
                  differenceInCalendarDays(new Date(dueDate), new Date());

                if (assignment.due_date) getDays(assignment.due_date);

                let localProgress = 0;
                if (assignedContent.content_type === 'Track') {
                  localProgress =
                    (assignment.completed_content.length / assignment.assigned_content.length) *
                    100;
                }
                return (
                  <DashboardCard
                    key={assignedContent.content_id._id}
                    id={assignedContent.content_id._id}
                    title={
                      assignedContent.content_id.type === 'track'
                        ? assignedContent.content_id.name
                        : assignedContent.content_id.title
                    }
                    type={assignedContent.content_id.type}
                    contextId={assignedContent.user_content_id}
                    imageUrl={assignedContent.content_id.banner_image}
                    isAssignment
                    isCompleted={assignment.completed}
                    dueIn={assignment.due_date ? getDays(assignment.due_date) : null}
                    progress={localProgress}
                    assingmentCompletedDate={assignment.completed_date}
                    originRoute="/dashboard"
                    showCompletedCheck={hasCompleted}
                  />
                );
              })}
            </Box>
          ))}
        </Stack>
      )}
    </>
  );
}
