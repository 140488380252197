import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import ThreeColumnLayout from '@/client/components/layout/admin/ThreeColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useTranslation } from 'react-i18next';

export default function Categories() {
  const { t } = useTranslation();
  const connect = useConnect('category');

  const columnOneTitle = t('admin.connect.categories.columnOneTitle');
  const columnTwoTitle = t('admin.connect.categories.columnTwoTitle');
  const columnThreeTitle = t('admin.connect.categories.columnThreeTitle');
  const categoriesPlural = t('admin.connect.categories.plural');
  const categoriesSingular = t('admin.connect.categories.singular');
  const subItemsPlural = t('admin.connect.categories.columnTwoTitlePlural');
  const subSubItemsPlural = t('admin.connect.categories.columnThreeTitlePlural');

  const columnCopy = {
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    noSubItemsText: t('admin.connect.noSubItems', {
      subType: columnTwoTitle,
      mainType: columnOneTitle,
    }),
    noSubSubItemsText: t('admin.connect.noSubSubItems', {
      subSubType: columnThreeTitle,
      subType: columnTwoTitle,
    }),
    noResultsMain: t('admin.connect.noSearchResultsMain', { type: categoriesPlural }),
    noResultsSub: t('admin.connect.noSearchResultsSub', {
      subType: subItemsPlural,
      mainType: columnOneTitle,
    }),
    noResultsSubSub: t('admin.connect.noSearchResultsSubSub', {
      subSubType: subSubItemsPlural,
      subType: columnTwoTitle,
    }),
  };

  return (
    <>
      <AdminPrimaryHeader
        showLanguageSelector
        tooltipText={t('admin.connect.searchPlaceholder', { type: categoriesSingular })}
        title={categoriesPlural}
        setSearchTerm={connect.setSearchTerm}
        sortOrder={connect.sortOrder}
        setSortOrder={connect.setSortOrder}
        isSearching={connect.isSearching}
      />

      <ThreeColumnLayout
        placeholderType={categoriesSingular}
        showExternal
        type="category"
        columnCopy={columnCopy}
        connect={connect}
      />

      <DeleteItemModal
        subType={subItemsPlural}
        subSubType={subSubItemsPlural}
        deleteItem={connect.deleteItem}
      />

    </>
  );
}
