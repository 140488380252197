import { Close } from '@/client/components/icons/ContinuIcons';
import { IconButton } from '@chakra-ui/react';

interface HeaderCloseIconProps {
  lightIcons?: boolean;
  navigateAction?: () => void;
}

export default function HeaderCloseIcon({ lightIcons, navigateAction }: HeaderCloseIconProps) {
  return (
    <IconButton
      aria-label="Close"
      variant="ghost"
      icon={
        <Close color={lightIcons ? 'white' : 'brand.iconGrey'} _hover={{ color: 'brand.button' }} />
      }
      onClick={navigateAction}
    />
  );
}

HeaderCloseIcon.defaultProps = {
  lightIcons: false,
  navigateAction: () => {},
};
