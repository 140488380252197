
import {  Button, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { getCurrencySign } from '@/client/services/api/graphql/gql-utils';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'usehooks-ts';
import { Product, ProductLicensingType } from '@/client/services/api/graphql/gql/graphql';

type SummaryProps = {
  productInfo: Product,
  handleSubmit: (event: any) => void,
  isComplete: boolean
}

export default function PaymentSummary({productInfo, handleSubmit, isComplete}: SummaryProps) {
  useDocumentTitle('Purchase Summary');
  const { t } = useTranslation();

  const subscriptionDurationKey = {
    DAILY: 'day',
    MONTHLY: 'month',
    WEEKLY: 'week',
    YEARLY: 'year',
    none: '',
  };
  const getProductPlan = (product: Product) => {
    const plan: any = {};
    const currencySign = getCurrencySign(product.currency || '');
    if (product.licensingType === ProductLicensingType.Subscription) {
      const duration = product.subscriptionDuration || 'none';
      plan.price = `${currencySign}${product.price}/${subscriptionDurationKey[duration]}`;
      plan.instruct = t('ecommerce.checkout.renewSubscription');
    } else {
      plan.price = `${currencySign}${product.price}`;
    }
    const today = new Date();
    plan.billDate = format(today, 'MM/d');
    return plan;
  };

  const productPlan = getProductPlan(productInfo);


  return (
    <Flex
      direction="column"
      h="100%"
      backgroundColor="blackAlpha.100"
      paddingTop="100px"
      paddingX="170px"
    >
      <Text fontWeight="600" textAlign="left" marginBottom={4}>
        {t('ecommerce.checkout.summary')}
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" templateRows="1fr 1% 10% 10% 30% 1% 1fr">
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {t('ecommerce.checkout.selectedProduct')}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={1} rowStart={1} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5} fontWeight={600}>
            {productInfo.title}
          </Text>
        </GridItem>
        <GridItem colStart={1} colSpan={2} rowStart={2} rowSpan={1}>
          <Divider borderTop=".25px solid" opacity="20%" />
        </GridItem>
        <GridItem colStart={1} colSpan={1} rowStart={3} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {t('ecommerce.checkout.selectedPlan')}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={1} rowStart={3} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5} fontWeight={600}>
            {productPlan.price}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={1} rowStart={4} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {t('ecommerce.checkout.billedOn')} {productPlan.billDate}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={1} rowStart={5} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {productPlan.instruct}
          </Text>
        </GridItem>
        <GridItem colStart={1} colSpan={2} rowStart={6} rowSpan={1}>
          <Divider borderTop=".25px solid" opacity="20%" />
        </GridItem>
        <GridItem colStart={1} colSpan={1} rowStart={7} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {t('ecommerce.checkout.howItWorks')}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={1} rowStart={7} rowSpan={1}>
          <Text fontSize="sm" textAlign="left" marginY={5}>
            {t('ecommerce.checkout.availableInExplore')}
          </Text>
        </GridItem>
      </Grid>
      <Button
        colorScheme="blue"
        type="submit"
        isDisabled={!isComplete}
        onClick={(event) => handleSubmit(event)}
      >
        {t('ecommerce.checkout.button.completeCheckout')}
      </Button>
    </Flex>
  );
}
