/* eslint-disable react/jsx-props-no-spreading */

import { Flex, IconButton, Input, useToken } from '@chakra-ui/react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { AxiosResponse } from 'axios';
import { Plus } from '@/client/components/icons/ContinuIcons';
import { UseMutationResult } from '@tanstack/react-query';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useTranslation } from 'react-i18next';
import PartnerPermissionModal from '../overlay/PartnerPermissionModal';
import { useConfigStore } from '@/client/services/state/configStore';
import { useAdminStore } from '@/client/services/state/admin/adminStore';

interface CategoryInputProps {
  placeholderType: string;
  type: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade' | 'skill';
  level: number;
  parentId: string | null | undefined;
  isSkill?: boolean;
  onAddItem: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>;
}

interface FormInputs {
  name: string;
  partnerPermissions?: string;
}

export default function ConnectInput({
  placeholderType,
  level,
  parentId,
  type,
  isSkill,
  onAddItem,
}: CategoryInputProps) {
  const { t } = useTranslation();
  const [brandButton] = useToken('colors', ['brand.button']);
  const { config } = useConfigStore();
  const { setPartnerPermissionModal } = useAdminStore();

  const methods = useForm<FormInputs>({ mode: 'onChange' });

  const onModalSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { name, partnerPermissions } = data;
    await onAddItem.mutateAsync({
      name,
      level,
      parentId,
      itemType: type,
      partnerPermissions,
    });
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { name } = data;

    if (type === 'category' && config.features.enable_partners) {
      setPartnerPermissionModal({
        isOpen: true,
        isControlled: true,
        selectedItem: {
          name,
          level,
          parentId,
          itemType: type,
        },
        value: 'tenant',
        handleSubmit: methods.handleSubmit(onModalSubmit),
      });
      return;
    }

    if (isSkill) {
      await onAddItem.mutateAsync(name);
    } else {
      await onAddItem.mutateAsync({
        name,
        level,
        parentId,
        itemType: type,
      });
    }

    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Flex paddingY={4} alignItems="center">
          <Input
            placeholder={`${t('global.actions.add')} ${placeholderType}`}
            backgroundColor="brand.mist"
            borderRadius="md"
            variant="unstyled"
            size="md"
            marginRight={6}
            paddingLeft={4}
            paddingY={2}
            fontSize="14px"
            {...methods.register('name', { required: true })}
          />

          <IconButton
            isDisabled={!methods.formState.isValid}
            aria-label={`Add ${placeholderType}`}
            borderRadius="full"
            size="xs"
            backgroundColor={brandButton}
            icon={<Plus />}
            _hover={{ backgroundColor: hexToRGBA(brandButton, 0.8) }}
            type="submit"
          />
        </Flex>
        {!!config.features.enable_partners && (
          <PartnerPermissionModal />
        )}
      </form>
    </FormProvider>
  );
}

ConnectInput.defaultProps = {
  isSkill: false,
};
