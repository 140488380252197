export const Input = {
  variants: {
    adminInput: {
      field: {
        background: 'rgba(249, 249, 249, 1)',
        fontSize: '14px',
        borderRadius: '5px',
        _placeholder: {
          color: 'brand.grey.60',
        },
      },
    },
    outline: {
      field: {
        _focus: {
          border: '1px solid brand.primary',
          borderColor: 'brand.primary',
          boxShadow: 'none',
        },
      },
    },
    flushed: {
      field: {
        borderColor: 'blackAlpha.500',
        _focus: {
          borderColor: 'brand.primary',
          boxShadow: 'none',
        },
      },
    },
    filled: {
      field: {
        _focus: {
          borderColor: 'brand.primary',
          boxShadow: 'none',
        },
      },
    },
    search: {
      field: {
        _focus: {
          minWidth: '300px',
          maxWidth: 'auto',
          flexGrow: '1',
          transition: '0.3s ease all',
          border: '1px',
          borderColor: 'brand.primary',
          boxShadow: 'none',
        },
      },
    },
    explore: {
      field: {
        width: '240px',
        transition: '0.3s ease all',
        margin: '0',
      },
    },
    workshops: {
      field: {
        minWidth: '270px',
        maxWidth: 'auto',
        background: '#F9F9F9',
        color: '#1A1A1A',
        _placeholder: {
          color: '#1A1A1A99',
        },
      },
    },
    createTitle: {
      field: {
        size: 'xl',
        fontSize: '36px',
        fontWeight: 600,
        color: 'brand.legibleBlack.100',
        background: 'transparent',
        borderRadius: 'none',
        borderBottom: '1px solid',
        borderBottomColor: 'brand.gold.20',
        _placeholder: {
          color: 'brand.legibleBlack.10',
        },
        _invalid: {
          borderBottom: '1px solid',
          borderBottomColor: 'red',
        },
      },
    },
    create: {
      field: {
        background: 'white',
        fontSize: 'sm',
        borderRadius: 'md',
        border: '1px solid',
        borderColor: 'brand.gold.40',
        _focus: {
          borderColor: 'brand.gold.100',
        },
        _placeholder: {
          color: 'brand.grey.60',
        },
        _invalid: {
          borderColor: 'red',
        },
      },
    },
    landingPage: {
      field: {
        _placeholder: { 
          color: "brand.grey.60" 
        },
        backgroundColor: "#ffffff",
        border: '1px solid #E2E8F0',
        borderRadius: "md",
        variant: "unstyled",
        paddingLeft: 4,
        marginBottom: 4,
        paddingY: 2,
        fontSize: "14px"
      }
    }
  },
};
