import { Delete } from '@/client/components/icons/ContinuIcons';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreateDeleteButtonProps {
  onClick: () => void;
}

export default function CreateDeleteButton({ onClick }: CreateDeleteButtonProps) {
  const { t } = useTranslation();

  return (
    <IconButton
      variant="ghost"
      aria-label={t('authoring.delete')}
      icon={<Delete color="red" />}
      onClick={onClick}
    />
  );
}
