import { Box, Button } from '@chakra-ui/react';

import RegistrationFormsList from '@/client/components/lists/RegistrationFormsList';
import { useNavigate } from 'react-router-dom';
import AdminTableWrapper from '@/client/components/admin/layout/AdminTableWrapper';
import { useTranslation } from 'react-i18next';

export default function LandingPageList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const addNewForm = () => {
    navigate('/admin/products/landing-page/create');
  };

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <Box
        padding={6}
        backgroundColor="white"
        margin={2}
        marginX={4}
        borderRadius="10px"
        boxShadow="0 2px 2px rgba(0,0,0,0.1)"
      >
      <AdminTableWrapper
        showSearch={false}
        tooltipText=''
        rightElement={
          <Button
            size="sm"
            variant="adminPrimary"
            borderRadius={8}
            onClick={addNewForm} 
          >
            {t('overviewProducts.addNewProduct')}
          </Button>
        }
      >
        <RegistrationFormsList type="product_landing_page" editPath="/admin/products/landing-page/" pageUrl="/product/registration"/>
      </AdminTableWrapper>
      </Box>
    </Box>
  );
}