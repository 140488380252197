import { Box, Flex, Text } from '@chakra-ui/react';

import { Category } from '@/client/types/Category';
import ConnectCard from '@/client/components/admin/connect/ConnectCard';
import ConnectInput from '@/client/components/admin/connect/ConnectInput';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { InView } from 'react-intersection-observer';
import Loading from '@/client/components/media/Loading';
import { UseConnectProps } from '@/client/services/hooks/admin/connect/useConnect';
import { useCallback } from 'react';
import { useCategoriesStore } from '@/client/services/state/admin/categoriesStore';
import PartnerPermissionModal from '../../admin/overlay/PartnerPermissionModal';

interface ThreeColumnListProps {
  items: Category[];
  title: string;
  type: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade';
  placeholderType: string;
  connect: UseConnectProps;
  handleMoveCardFunc: (dragIndex: number, hoverIndex: number) => void;
  noSearchResultsCopy: string;
  isDragDisabled: boolean;
  showExternal: boolean;
  noItemsText: string;
  level: number;
  showInput: boolean;
  parentId: string | null | undefined;
  isLoading: boolean;
  fetchNextPage: any;
}

export default function ThreeColumnList({
  items,
  title,
  type,
  placeholderType,
  connect,
  handleMoveCardFunc,
  noSearchResultsCopy,
  isDragDisabled,
  showExternal,
  noItemsText,
  level,
  showInput,
  parentId,
  isLoading,
  fetchNextPage,
}: ThreeColumnListProps) {
  const { activeMainCategory, activeSubCategory } = useCategoriesStore();
  

  const renderCard = useCallback(
    (
      category: Category,
      index: number,
      dragAction: (dragIndex: number, hoverIndex: number) => void,
    ) => (
      <ConnectCard
        isDragDisabled={connect.isSearching || isDragDisabled}
        showExternalOption={showExternal}
        key={category._id}
        category={category}
        index={index}
        moveCard={dragAction}
        connect={connect}
      />
    ),
    [connect.isSearching],
  );

  return (
    <>
      <Text fontSize="12px" fontWeight="medium" color="brand.grey.50" marginBottom={2}>
        {title}
      </Text>

      <Flex
        direction="column"
        minHeight="75vh"
        maxHeight="75vh"
        backgroundColor="white"
        paddingY={4}
        paddingX={8}
        borderRadius="10px"
      >
        {showInput && (
          <ConnectInput
            onAddItem={connect.addItem}
            level={level}
            parentId={parentId}
            type={type}
            placeholderType={placeholderType}
          />
        )}

        <Box flex={1} overflow="scroll" paddingBottom={12}>
          <>
            {connect.levelOneStatus === 'loading' ||
              (connect.isSearching && connect.fullTextSearchStatus === 'loading' && <Loading />)}

            {connect.isSearching && items.length === 0 && (
              <Text align="center" padding={14} fontSize="14px">
                {noSearchResultsCopy}
              </Text>
            )}

            {!connect.isSearching && items.length === 0 && (
              <Text align="center" padding={14} fontSize="14px">
                {noItemsText}
              </Text>
            )}

            <DndProvider backend={HTML5Backend}>
              {items.length > 0 &&
                items.map((item, index) => renderCard(item, index, handleMoveCardFunc))}
            </DndProvider>

            {!connect.isSearching && (
              <>
                <InView
                  as="div"
                  onChange={(inView) => {
                    if (inView) {
                      if (level === 1) {
                        fetchNextPage();
                      } else if (level === 2 && !!activeMainCategory) {
                        fetchNextPage();
                      } else if (level === 3 && !!activeSubCategory) {
                        fetchNextPage();
                      }
                    }
                  }}
                />

                {isLoading && <Loading />}
              </>
            )}
          </>
        </Box>
      </Flex>
      {!!parentId && <PartnerPermissionModal />}
    </>
  );
}
