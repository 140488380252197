import { ArrowRight } from '@/client/components/icons/ContinuIcons';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreateNextButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

export default function CreateNextButton({ isDisabled, onClick }: CreateNextButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant="createSolid"
      rightIcon={<ArrowRight />}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {t('authoring.next')}
    </Button>
  );
}
