import { getSupportedLanguages } from '@/client/utils/getSupportedLanguages';
import { useAuthStore } from '../../state/authStore';
import { useCategoriesStore } from '../../state/admin/categoriesStore';

export const useLanguage = () => {
  const { selectedLanguage, setSelectedLanguage, defaultLanguage } =
    useCategoriesStore();
  const supportedLanguages = getSupportedLanguages();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  let availableLanguages = company.available_languages;

  availableLanguages = availableLanguages.map((lang) => {
    if (lang === 'eng') {
      return 'en';
    }

    if (lang === 'spa') {
      return 'es_LA';
    }

    return lang;
  });

  const getAvailableLanguages = () => {
    const languages = [];

    if (availableLanguages && availableLanguages.length) {
      for (let i = 0; i < supportedLanguages.length; i += 1) {
        if (!supportedLanguages[i].abr) {
          continue;
        }

        if (availableLanguages.indexOf(supportedLanguages[i].abr) !== -1) {
          languages.push(supportedLanguages[i]);
        }
      }

      if (!languages.length) {
        return [defaultLanguage];
      }

      return languages;
    }

    return [defaultLanguage];
  };

  return {
    defaultLanguage,
    getAvailableLanguages,
    selectedLanguage,
    setSelectedLanguage,
  };
};
