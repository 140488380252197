import { Stack, Text } from '@chakra-ui/react';

import { Category } from '@/client/types/Category';
import ConnectCard from '@/client/components/admin/connect/ConnectCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { InView } from 'react-intersection-observer';
import { UseConnectProps } from '@/client/services/hooks/admin/connect/useConnect';

interface SingleColumnListProps {
  items: Category[];
  connect: UseConnectProps;
  noSearchResultsText: string;
}

export default function SingleColumnList({
  items,
  connect,
  noSearchResultsText,
}: SingleColumnListProps) {
  return connect.isSearching &&
    connect.searchStatus === 'success' &&
    connect.searchItems.length === 0 ? (
    <Text align="center" padding={14} fontSize="14px">
      {noSearchResultsText}
    </Text>
  ) : (
    <Stack>
      <DndProvider backend={HTML5Backend}>
        {items.map((item, index) => (
          <ConnectCard
            isDragDisabled
            key={item._id}
            index={index}
            category={item}
            moveCard={() => {}}
            connect={connect}
          />
        ))}
      </DndProvider>

      <InView
        as="div"
        onChange={(inView) => {
          if (inView) {
            connect.isSearching ? connect.searchFetchNextPage() : connect.levelOneFetchNextPage();
          }
        }}
      />
    </Stack>
  );
}
