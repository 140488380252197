import { analyzeApiClient } from './clients/analyzeApiClient';

const getContentStats = async (ids: string | undefined) => {
  const response = await analyzeApiClient.get(`/explore/count-items?content=${ids}`);
  return response.data;
};

const getUserContentViews = async (_id: string | undefined, date: string | undefined) => {
  const response = await analyzeApiClient.get(
    `views/count?created_on=$gte,date-${date}&fields=user,created_on&user=${_id}`,
  );
  return response.data;
};

const postViews = async (contentId: string | undefined, contentType: string) => {
  const response = await analyzeApiClient.post(`views`, {
    content: contentId,
    content_type: contentType,
  });

  return response.data;
};

const sendContentSession = async (
  contentId: string | undefined,
  contentType: string,
  duration: number,
) => {
  const response = await analyzeApiClient.post(`content-session`, {
    content: contentId,
    content_type: contentType,
    duration,
  });

  return response.data;
};

const postShares = async (
  contentId: string | undefined,
  contentType: string,
  recipient: string,
) => {
  const response = await analyzeApiClient.post(`shares`, {
    content: contentId,
    content_type: contentType,
    recipient,
  });

  return response.data;
};

const recordUserVideoPosition = async (
  contentId: string | undefined,
  percentProgressed: number,
) => {
  const response = await analyzeApiClient.post(`user-videos`, {
    content: contentId,
    position: percentProgressed,
  });

  return response.data;
};

const getUserVideoPosition = async (contentId: string | undefined) => {
  const response = await analyzeApiClient.get(`user-videos/${contentId}`);

  return response.data;
};

const recordUserPdfPosition = async (contentId: string, percentProgressed: number) => {
  const response = await analyzeApiClient.post(`user-pdfs`, {
    content: contentId,
    position: percentProgressed,
  });

  return response.data;
};

const getUserPdfPosition = async (contentId: string) => {
  const response = await analyzeApiClient.get(`user-pdfs/${contentId}`);

  return response.data;
};

const recordUserAgents = async (userAgent: string) => {
  const response = await analyzeApiClient.post(`useragents`, {
    useragent: userAgent,
  });

  return response.data;
};

const recordAuthTokens = async (action: string) => {
  const response = await analyzeApiClient.post(`authtokens`, {
    action,
  });

  return response.data;
};

const recordAvatars = async (image: string) => {
  const response = await analyzeApiClient.post(`avatars`, {
    image,
  });

  return response.data;
};

const AnalyzeService = {
  getContentStats,
  getUserContentViews,
  postViews,
  sendContentSession,
  postShares,
  recordUserVideoPosition,
  getUserVideoPosition,
  recordUserPdfPosition,
  getUserPdfPosition,
  recordUserAgents,
  recordAuthTokens,
  recordAvatars,
};

export default AnalyzeService;
