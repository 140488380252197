import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreatePublishButtonProps {
  onClick: () => void;
}

export default function CreatePublishButton({ onClick }: CreatePublishButtonProps) {
  const { t } = useTranslation();

  return (
    <Button variant="createSolid" onClick={onClick}>
      {t('authoring.publish')}
    </Button>
  );
}
