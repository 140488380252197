import { HStack, Text } from '@chakra-ui/react';
import { secondsToHours, secondsToMinutes } from 'date-fns';

import { Clock } from '@/client/components/icons/ContinuIcons';
import { useTranslation } from 'react-i18next';

type ContentLengthProps = {
  duration: number;
  color?: string;
  size?: string;
};

export default function ContentLength({ duration, color, size }: ContentLengthProps) {
  const { t } = useTranslation();

  const getDisplayDuration = () => {
    const hours = secondsToHours(duration);
    const minutes = secondsToMinutes(duration);

    let timeFmt = `${hours} hour${hours > 1 ? 's' : ''}`;

    if (minutes < 1) {
      timeFmt = t('edit.article.lessThanAMinute');
    } else if (duration < 3600) {
      timeFmt = `${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    return timeFmt;
  };

  return (
    <HStack spacing={2}>
      <Clock color={color} />

      <Text fontSize={size || 'xs'} color={color}>
        {getDisplayDuration()}
      </Text>
    </HStack>
  );
}

ContentLength.defaultProps = {
  color: 'black',
  size: null,
};
