import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import SkillService from '@/client/services/api/SkillService';
import { useAuthStore } from '@/client/services/state/authStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export const useSkillService = (blocked: boolean | undefined) => {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const user = authConfig?.user;
  const { setToast } = useToastStore();
  const perPage = 50;
  const [items, setItems] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchItems, setSearchItems] = useState<any[]>([]);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | 'oldest' | 'newest'>('newest');

  useEffect(() => {
    if (!searchTerm) {
      setIsSearching(false);
      setActiveItemId(null);
    }

    if (searchTerm) {
      setIsSearching(true);
    }
  }, [searchTerm]);

  const { status, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery({
    queryKey: ['connect-skills', blocked, sortOrder],
    queryFn: ({ pageParam = 1 }) => SkillService.getSkills(pageParam, blocked, sortOrder),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === perPage ? pages.length + 1 : undefined,
    onSuccess: (data) => setItems(data.pages.map((page) => page).flat()),
  });

  const {
    status: searchStatus,
    fetchNextPage: searchFetchNextPage,
    isFetchingNextPage: searchIsFetchingNextPage,
    refetch: searchRefetch,
  } = useInfiniteQuery({
    enabled: !!isSearching && !!searchTerm,
    queryKey: ['connect-skills-search', searchTerm, blocked, sortOrder],
    queryFn: ({ pageParam = 1 }) =>
      SkillService.searchSkills(searchTerm!, sortOrder, pageParam, blocked),
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length === perPage ? pages.length + 1 : undefined,
    onSuccess: (data) => setSearchItems(data.pages.map((page) => page.data).flat()),
  });

  const addSkill = useMutation({
    mutationFn: (name: string) => SkillService.createSkills([name], user._id),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.createSuccess', { type: 'Skill' }),
      });

      refetch();
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.createError', { type: 'Skill' }),
      });
    },
  });

  interface BlockSkillsVars {
    existingSkillIds: string[];
    newSkillIds: string[];
  }

  const blockSkills = useMutation({
    mutationFn: ({ existingSkillIds, newSkillIds }: BlockSkillsVars) =>
      SkillService.blockSkills(existingSkillIds, newSkillIds, user?._id!),
    onSuccess: (data, variables) => {
      const skillCount = variables.existingSkillIds.length + variables.newSkillIds.length;

      isSearching ? searchRefetch() : refetch();

      setToast({
        show: true,
        status: 'success',
        title: `Successfully blocked ${skillCount} skill(s)`,
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: 'Error blocking skills',
      }),
  });

  interface UnblockSkillsVars {
    skillIds: string[];
  }

  const unblockSkills = useMutation({
    mutationFn: ({ skillIds }: UnblockSkillsVars) => SkillService.unblockSkills(skillIds),
    onSuccess: (data, variables) => {
      isSearching ? searchRefetch() : refetch();

      setToast({
        show: true,
        status: 'success',
        title: `Successfully unblocked ${variables.skillIds.length} skill(s)`,
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: 'Error unblocking skills',
      }),
  });

  const deleteSkills = useMutation({
    mutationFn: ({ skillIds }: UnblockSkillsVars) => SkillService.deleteSkills(skillIds),
    onSuccess: (data, variables) => {
      isSearching ? searchRefetch() : refetch();

      setToast({
        show: true,
        status: 'success',
        title: `Successfully deleted ${variables.skillIds.length} skill(s)`,
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: 'Error deleting skills',
      }),
  });

  const editSkill = useMutation({
    mutationFn: ({ skillId, name }: { skillId: string; name: string }) =>
      SkillService.editSkill(skillId, name),
    onSuccess: () => {
      isSearching ? searchRefetch() : refetch();

      setToast({
        show: true,
        status: 'success',
        title: `Successfully edited skill`,
      });
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: 'Error editing skill',
      });
    },
  });

  return {
    items,
    setItems,
    status,
    fetchNextPage,
    isFetchingNextPage,
    searchStatus,
    searchFetchNextPage,
    searchIsFetchingNextPage,
    searchTerm,
    setSearchTerm,
    searchItems,
    isSearching,
    setIsSearching,
    activeItemId,
    setActiveItemId,
    blockSkills,
    unblockSkills,
    deleteSkills,
    sortOrder,
    setSortOrder,
    editSkill,
    addSkill,
  };
};
