export const NumberInput = {
  variants: {
    adminInput: {
      field: {
        background: 'rgba(249, 249, 249, 1)',
        fontSize: '14px',
        borderRadius: '5px',
        _placeholder: {
          color: 'brand.grey.60',
        },
      },
    },
  },
};
