import './learningTrackStyles.scss';

import {
  ArrowLeft,
  ArrowRight,
  Check,
  Facebook,
  LinkedIn,
  Twitter,
} from '@/client/components/icons/ContinuIcons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import Celebration from '../../../components/lists/track/Celebration';
import Certificate from '@/client/components/data-display/certificates/Certificate';
import CollapsableHtmlRenderer from '../../../components/html/CollapsableHtmlRenderer';
import CommentList from '../../../components/lists/CommentList';
import ContentHeader from '../../../components/layout/ContentHeader';
import ContentLength from '../../../components/data-display/content/ContentLength';
import ContentRatingGroup from '@/client/components/data-display/ratings/ContentRatingGroup';
import ContentRatingInput from '@/client/components/data-display/ratings/ContentRatingInput';
import ContentService from '@/client/services/api/ContentService';
import { Course } from '@/client/types/content/learning-tracks/LearningTrack';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '../../../components/html/HtmlRenderer';
import { IoSparklesSharp } from 'react-icons/io5';
import Loading from '@/client/components/media/Loading';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import Recommendations from '@/client/components/layout/Recommendations';
import TrackContentList from '../../../components/lists/track/TrackContentList';
import TrackService from '@/client/services/api/TrackService';
import TrackViewer from '../TrackViewer';
import ViewActions from '@/client/components/overlay/ViewActions';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useGetLearningTrack } from '@/client/services/hooks/content/learning-tracks/useGetLearningTrack';
import { useRecommendationsStore } from '@/client/services/state/recommendations/recommendationsStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface LearningTrackProps {
  journeyId?: string;
  contentId?: string;
  contextId?: string;
  needsContextSelection?: boolean;
}

export default function LearningTrack({
  journeyId,
  contentId,
  contextId,
  needsContextSelection,
}: LearningTrackProps) {
  const { recommendationsLoaded } = useRecommendationsStore();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const routeParams = useParams();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const { partner } = usePartnerStore();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { allowView } = useCheckContentAuth();
  const [lastViewedContent, setLastViewedContent] = useState<string>('');
  const [trackViewerOpen, setTrackViewerOpen] = useState<boolean>(false);
  const [viewingCert, setViewingCert] = useState<boolean>(false);
  const [currentCourseIndex, setCurrentCourseIndex] = useState<number>(0);
  const [nextCourseIsAvailable, setNextCourseIsAvailable] = useState<boolean>(false);
  const [showPrevNext, setShowPrevNext] = useState<boolean>(true);
  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false);
  const [celebrationIsOpen, setCelebrationIsOpen] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);
  const heroRef = useRef<HTMLDivElement>(null);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [renderRecommendations, setRenderRecommendations] = useState<boolean>(false);

  const id = (contentId || routeParams.learningTrackId) as string;
  const inJourney = !!journeyId;

  const { isLoading, isError, data, refetch, failureReason } = useGetLearningTrack(
    id,
    user._id,
    contextId,
    needsContextSelection,
  );

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.learningTrack);
        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useEffect(() => {
    if (isAllowed) {
      AnalyzeService.postViews(id, 'track');
      ContentService.postViews(id, 'track');
    }
  }, [isAllowed]);

  useEffect(() => {
    if (selectedCourse) {
      if (data && data.courseList) {
        const currentCourseIndex = data.courseList.findIndex(
          (course) => course.contentId === selectedCourse.contentId,
        );

        setCurrentCourseIndex(currentCourseIndex);

        const nextCourse = data.courseList[currentCourseIndex + 1];

        handleNextIsAvailable(nextCourse, data.trackProgress);

        if (selectedCourse.contentType === 'assessment') {
          let currentAssessmentPassed = data.userTrack?.completed_assessments.find(
            (id) => id === selectedCourse.contentId,
          );

          currentAssessmentPassed ? setShowPrevNext(true) : setShowPrevNext(false);
        } else {
          setShowPrevNext(true);
        }
      }
    }
  }, [selectedCourse, data]);

  const findSelectedCourseIndex = (
    courseList: Course[] | undefined,
    course: Course | undefined,
  ) => {
    if (!courseList || !selectedCourse) {
      return;
    }

    return courseList.findIndex((c) => c.contentId === course?.contentId);
  };

  const handleNextIsAvailable = (course: Course, progress: number) => {
    if ((course && course.available) || progress === 100) {
      setNextCourseIsAvailable(true);
    } else {
      setNextCourseIsAvailable(false);
    }
  };

  useEffect(() => {
    if (data) {
      if (routeParams.contentType && routeParams.id) {
        setSelectedCourse(data.courseList.find((course) => course.contentId === routeParams.id));

        const currentCourseIndex = data.courseList.findIndex(
          (course) => course.contentId === routeParams.id,
        );
        const nextCourse = data.courseList[currentCourseIndex + 1];

        handleNextIsAvailable(nextCourse, data.trackProgress);

        setCurrentCourseIndex(currentCourseIndex);
        setTrackViewerOpen(true);
        return;
      }

      if (data.userTrack) {
        if (data.trackProgress === 100) {
          if (data.learningTrack.certificate && !data.userTrack.viewed_certificate) {
            setViewingCert(true);
            markCertificatedViewed.mutate({ userTrackId: data.userTrack._id });
          }

          if (
            data.learningTrack.rating_configuration?.allow_rating &&
            data.userRating.length === 0
          ) {
            setRatingModalOpen(true);
          }
        }

        checkShowPopupSurvey(data.learningTrack, data.trackProgress === 100, inJourney);
      }
      setTrackViewerOpen(false);
    }
  }, [routeParams, data]);

  interface MarkCertificatedViewedVariables {
    userTrackId: string;
  }

  const setExternalUrl = (url: string) => {
    const isHttps = url.includes('https://');
    const isHttp = url.includes('http://');

    if (isHttps) return url.replace('https://', '');
    if (isHttp) return url.replace('http://', '');

    return url;
  };

  // Handles navigation within track viewer
  const handleNavigate = (selectedCourse: Course, courseList: any[], type: 'next' | 'previous') => {
    const currentCourseIndex = findSelectedCourseIndex(courseList, selectedCourse);

    if (currentCourseIndex !== undefined) {
      setCurrentCourseIndex(currentCourseIndex);

      const nextCourse = courseList[currentCourseIndex + 1];
      const previousCourse = courseList[currentCourseIndex - 1];

      if (type === 'next') {
        if (inJourney) {
          setSelectedCourse(nextCourse);
          return;
        }
        navigate(`/track/${id}${nextCourse.contentLink}`);
        return;
      } else {
        if (inJourney) {
          setSelectedCourse(previousCourse);
          return;
        }
        navigate(`/track/${id}${previousCourse.contentLink}`);
      }
    }
  };

  // Handles initial navigation to course within track viewer
  const handleGoToCourse = async (course: Course) => {
    if (!course?.contentLink && !data?.courseList[0].contentLink && !inJourney) {
      setToast({
        show: true,
        status: 'error',
        title: t('contentViews.tracks.contentNotAvailable'),
      });
      return;
    }
    setSelectedCourse(course);

    if (!inJourney) {
      if (course?.contentLink) {
        navigate(`/track/${id}${course.contentLink}`);
      } else {
        navigate(`/track/${id}${data?.courseList[0].contentLink}`);
      }
    }
  };

  const goToLastViewedContent = (lastViewedContent: Course) => {
    if (inJourney) {
      setSelectedCourse(lastViewedContent);
      setTrackViewerOpen(true);
    } else {
      navigate(`/track/${id}${lastViewedContent.contentLink}`);
    }
  };

  const backToTrack = () => {
    setSelectedCourse(undefined);

    if (!inJourney) {
      navigate(`/track/${id}`);
      return;
    }
  };

  const handleContentCompletion = async () => {
    await refetch();
  };

  const handleAfterComplete = () => {
    backToTrack();
    setCelebrationIsOpen(true);
  };

  const createUserTrack = useMutation({
    mutationFn: async () => await TrackService.createUserTrack(id, user._id),
    onSuccess: () =>
      refetch().then(() => {
        setSelectedCourse(data?.courseList[0]);

        if (!inJourney) {
          navigate(`/track/${id}${data?.courseList[0].contentLink}`);
        }
      }),
    onError: (err) => {
      if (err === null) {
        setToast({
          show: true,
          status: 'error',
          title: t('trackviewer.message.error_processing'),
        });
        return;
      }

      setToast({
        show: true,
        status: 'error',
        title: t('trackviewer.message.error_creating'),
      });
    },
  });

  const markCertificatedViewed = useMutation({
    mutationFn: async ({ userTrackId }: MarkCertificatedViewedVariables) =>
      TrackService.markCertificateViewed(userTrackId),
    onSuccess: () => queryClient.invalidateQueries(['query-learning-track', id]),
  });

  useDocumentTitle(data ? data.learningTrack.name : 'Learning Track', true);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get track" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <>
      {isAllowed && (
        <>
          {selectedCourse && (
            <Box position="relative">
              <TrackViewer
                allowComments={data.learningTrack.allow_comments}
                selectedCourse={selectedCourse}
                trackId={id}
                journeyId={journeyId}
                trackName={data.learningTrack.name}
                sections={data.formattedTrack.sections}
                lastViewedContent={lastViewedContent}
                userTrackId={data.userTrack?._id}
                userTrack={data.userTrack}
                handleGoToCourse={handleGoToCourse}
                backToTrack={backToTrack}
                handleContentCompletion={handleContentCompletion}
              />

              {showPrevNext && (
                <Box
                  position="fixed"
                  backgroundColor="white"
                  bottom={0}
                  left={{base: 0, sm: 50}}
                  right={0}
                  boxShadow="0 0 10px rgb(0 0 0 / 10%)"
                >
                  <Box
                    height="3px"
                    bgGradient="linear(to-r, brand.primary, brand.secondary)"
                    width={`${data.trackProgress}%`}
                  />

                  <Flex padding={4} alignItems="center" justify="space-between">
                    <Button
                      variant="cancel"
                      color="gray.500"
                      leftIcon={<ArrowLeft />}
                      isDisabled={currentCourseIndex === 0}
                      onClick={() => handleNavigate(selectedCourse, data.courseList, 'previous')}
                    >
                      {t('global.form.labels_previous')}
                    </Button>

                    <Text fontSize="xs">
                      {data.courseList.filter((course) => course.completed).length}/
                      {data.courseList.length} {t('track.view.itemsCompleted')} (
                      {data.trackProgress}
                      %)
                    </Text>

                    {currentCourseIndex + 1 === data.courseList.length ? (
                      <Button
                        rightIcon={<ArrowRight />}
                        variant="solid"
                        isDisabled={data.trackProgress !== 100}
                        onClick={() => handleAfterComplete()}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        rightIcon={<ArrowRight />}
                        variant="solid"
                        isDisabled={!nextCourseIsAvailable}
                        onClick={() => handleNavigate(selectedCourse, data.courseList, 'next')}
                      >
                        {t('global.form.labels_next')}
                      </Button>
                    )}
                  </Flex>
                </Box>
              )}

              <ViewActions buttons={['gototop']} bottomOffset={20} />
            </Box>
          )}

          {!selectedCourse && (
            <>
              <Flex
                height={{
                  base: data.resource ? 'auto' : '300px',
                  md: data.resource ? 'auto' : '400px',
                  lg: data.resource ? 'auto' : '500px',
                }}
                width="full"
                position="relative"
                ref={heroRef}
              >
                <Box
                  w="100%"
                  h="100%"
                  background="black"
                  position="absolute"
                  top="0"
                  left="0"
                  zIndex="3"
                  opacity="0.4"
                />
                <Box
                  width="full"
                  height="full"
                  bgColor="brand.primary"
                  backgroundImage={`url(${data.learningTrack.banner_image})`}
                  backgroundSize="cover"
                  backgroundPosition="center center"
                >
                  {!inJourney && (
                    <ContentHeader
                      contentType="track"
                      contentTitle={data.learningTrack.name}
                      contentId={id}
                      allowComments={data.learningTrack.allow_comments}
                      heroRef={heroRef}
                      goToComments={() =>
                        setActiveTabIndex(data.learningTrack.creator_notes ? 2 : 1)
                      }
                      goToRecommendations={() => {
                        if (data.learningTrack.allow_comments && data.learningTrack.creator_notes) {
                          setActiveTabIndex(3);
                        } else if (
                          !data.learningTrack.allow_comments &&
                          data.learningTrack.creator_notes
                        ) {
                          setActiveTabIndex(2);
                        } else if (
                          !data.learningTrack.allow_comments &&
                          !data.learningTrack.creator_notes
                        ) {
                          setActiveTabIndex(1);
                        }
                      }}
                      navigateAction={
                        location.state?.originRoute
                          ? () => navigate(location.state.originRoute)
                          : () => navigate('/explore')
                      }
                    />
                  )}

                  <Center
                    width="full"
                    marginTop={{ base: 6, md: 24 }}
                    marginBottom={data.resource ? { base: 6, md: 24 } : ''}
                    position="relative"
                    zIndex="5"
                  >
                    <VStack spacing={4}>
                      <Text variant="heroheader" color="white" marginBottom="0">
                        {data.learningTrack.name}
                      </Text>

                      {data.trackDuration > 0 && !data.resource && (
                        <ContentLength duration={data.trackDuration} color="white" size="lg" />
                      )}

                      {data.learningTrack.rating_configuration &&
                        data.learningTrack.rating_configuration.allow_rating &&
                        data.learningTrack.average_rating && (
                          <Box fontSize="sm" color="white" display="flex">
                            <ContentRatingGroup
                              averageRating={data.learningTrack.average_rating}
                              ratingConfiguration={data.learningTrack.rating_configuration}
                            />
                          </Box>
                        )}

                      {!data.hasCompleted && !data.resource && (
                        <Box>
                          <Button
                            rightIcon={<ArrowRight />}
                            marginY={4}
                            paddingY={4}
                            paddingX={8}
                            backgroundColor="brand.button"
                            _hover={{
                              backgroundColor: 'brand.button',
                            }}
                            onClick={
                              data.trackProgress > 0
                                ? () => goToLastViewedContent(data.lastViewedContent)
                                : () => handleGoToCourse(data.courseList[0])
                            }
                          >
                            {data.trackProgress > 0
                              ? t('contentViews.tracks.continue')
                              : t('contentViews.tracks.start')}
                          </Button>
                        </Box>
                      )}

                      {data.hasCompleted && data.learningTrack.certificate && (
                        <Box>
                          <Button
                            rightIcon={<Check />}
                            onClick={() => {
                              setViewingCert(true);
                            }}
                            marginY={4}
                            paddingY={6}
                            paddingX={10}
                            colorScheme={data.learningTrack.banner_image ? 'green' : ''}
                            backgroundColor={
                              data.learningTrack.banner_image ? 'green' : 'blackAlpha.500'
                            }
                            _hover={{
                              backgroundColor: data.learningTrack.banner_image
                                ? 'green'
                                : 'blackAlpha.500',
                            }}
                          >
                            {t('contentViews.tracks.viewCertificate')}
                          </Button>
                        </Box>
                      )}

                      {data.hasCompleted && !data.learningTrack.certificate && (
                        <Box>
                          <Button
                            marginY={4}
                            paddingY={6}
                            paddingX={10}
                            colorScheme={data.learningTrack.banner_image ? 'green' : ''}
                            backgroundColor={
                              data.learningTrack.banner_image ? 'green' : 'blackAlpha.500'
                            }
                            rightIcon={<Check />}
                            _hover={{
                              backgroundColor: data.learningTrack.banner_image
                                ? 'green'
                                : 'blackAlpha.500',
                            }}
                          >
                            {t('contentViews.tracks.trackComplete')}
                          </Button>
                        </Box>
                      )}

                      {data.trackProgress > 0 && !data.resource && (
                        <Flex width={80} alignItems="center" paddingX={14}>
                          <Text fontSize="xs" flex="1" color="white" fontWeight="medium">
                            {data.trackProgress}% {t('contentViews.tracks.complete')}
                          </Text>

                          <Progress
                            flex="1"
                            width="full"
                            size="xs"
                            value={data.trackProgress}
                            variant="hollow"
                          />
                        </Flex>
                      )}
                    </VStack>
                  </Center>
                </Box>
              </Flex>

              <Center width={{ base: '100%', md: '70%' }} margin="0 auto" marginTop={8}>
                <Tabs
                  index={activeTabIndex}
                  width="full"
                  isFitted
                  onChange={(index) => setActiveTabIndex(index)}
                >
                  <TabList marginBottom={8} width="auto" justifyContent="center" paddingBottom={1}>
                    <Tab flex="0" marginRight={data.learningTrack.allow_comments ? 2 : 0}>
                      {t('contentViews.tracks.info_tabName')}
                    </Tab>

                    {data.learningTrack.creator_notes &&
                      data.learningTrack.creator_notes !== '' && (
                        <Tab flex="0">{t('contentViews.tracks.instructor_tabName')}</Tab>
                      )}

                    {data.learningTrack.allow_comments && !partner._id && (
                      <Tab flex="0">{t('trackviewer.discussion')}</Tab>
                    )}

                    {!inJourney && data.trackProgress === 100 && recommendationsLoaded && (
                      <Tab flex="0">
                        <Flex alignItems="center">
                          <Text marginRight={2}>Recommend</Text>

                          <Icon as={IoSparklesSharp} />
                        </Flex>
                      </Tab>
                    )}
                  </TabList>

                  <TabPanels>
                    <TabPanel maxWidth="1060px" margin="0 auto">
                      <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
                        <Box width={{ base: '100%', lg: '50%' }}>
                          {data.learningTrack.learn_items?.length > 0 && (
                            <Box marginBottom={8}>
                              <Text as="h4" fontSize={'md'}>
                                {t('contentViews.tracks.learn_headline')}
                              </Text>

                              {data.learningTrack.learn_items.map((item: any, index: number) => {
                                if (item !== '') {
                                  return (
                                    <HStack key={index} spacing={4}>
                                      <Check color="brand.primary" />

                                      <Text fontSize="sm">{item}</Text>
                                    </HStack>
                                  );
                                }
                              })}
                            </Box>
                          )}
                          <CollapsableHtmlRenderer html={data.learningTrack.description} />

                          <TrackContentList
                            sections={data.formattedTrack.sections}
                            lastViewedContent={data.lastViewedContent}
                            userTrack={data.userTrack}
                            trackComplete={data.hasCompleted}
                            handleGoToCourse={handleGoToCourse}
                          />
                        </Box>

                        <Flex
                          width={{ base: '100%', lg: '50%' }}
                          justifyContent={{ base: 'center', lg: 'flex-end' }}
                          alignSelf="flex-start"
                        >
                          <Box
                            backgroundColor="blackAlpha.50"
                            width="70%"
                            marginBottom={10}
                            paddingY={12}
                            paddingX={4}
                          >
                            <VStack spacing={6}>
                              <Avatar
                                name={data.learningTrack.creator_name}
                                src={data.learningTrack.creator_image}
                                backgroundColor="blackAlpha.300"
                                color="blackAlpha.600"
                              />

                              {data.learningTrack.creator_email && (
                                <Link href={`mailto:${data.learningTrack.creator_email}`}>
                                  {data.learningTrack.creator_email}
                                </Link>
                              )}

                              <HStack spacing={6}>
                                {data.learningTrack.creator_twitter && (
                                  <a
                                    href={`//${setExternalUrl(data.learningTrack.creator_twitter)}`}
                                    target="_blank"
                                  >
                                    <Twitter color="blackAlpha.600" />
                                  </a>
                                )}

                                {data.learningTrack.creator_linkedin && (
                                  <a
                                    href={`//${setExternalUrl(
                                      data.learningTrack.creator_linkedin,
                                    )}`}
                                    target="_blank"
                                  >
                                    <LinkedIn color="blackAlpha.600" />
                                  </a>
                                )}

                                {data.learningTrack.creator_facebook && (
                                  <a
                                    href={`//${setExternalUrl(
                                      data.learningTrack.creator_facebook,
                                    )}`}
                                    target="_blank"
                                  >
                                    <Facebook color="blackAlpha.600" />
                                  </a>
                                )}
                              </HStack>

                              <Text as="h4">{data.learningTrack.creator_name}</Text>

                              <Box className="author-bio" textAlign="center" paddingX={6}>
                                <HtmlRenderer html={data.learningTrack.creator_bio} />
                              </Box>
                            </VStack>
                          </Box>
                        </Flex>
                      </Flex>
                    </TabPanel>

                    {data.learningTrack.creator_notes &&
                      data.learningTrack.creator_notes !== '' && (
                        <TabPanel>
                          <HtmlRenderer html={data.learningTrack.creator_notes} />
                        </TabPanel>
                      )}

                    {data.learningTrack.allow_comments && !partner._id && (
                      <TabPanel maxWidth="640px" margin="0 auto">
                        <CommentList
                          contentId={id}
                          contentType="track"
                          contentTitle={data.learningTrack.name}
                        />
                      </TabPanel>
                    )}

                    {!inJourney && data.trackProgress === 100 && (
                      <TabPanel>
                        <Recommendations contentId={id} />
                      </TabPanel>
                    )}
                  </TabPanels>
                </Tabs>
              </Center>
              <ViewActions buttons={['gototop']} bottomOffset={showPopup ? 10 : 0} />

              {viewingCert && (
                <Certificate
                  contentTitle={data.learningTrack.name}
                  userFullName={user.full_name}
                  completedDate={data.completedDate}
                  userMetadata={user.metadata}
                  contentMetadata={data.learningTrack.metadata}
                  certificateData={data.learningTrack.certificate_data}
                  onClose={() => {
                    setViewingCert(false);
                  }}
                />
              )}
            </>
          )}

          <Modal
            isOpen={ratingModalOpen}
            isCentered
            size="3xl"
            onClose={() => setRatingModalOpen(false)}
          >
            <ModalOverlay />

            <ModalContent>
              <ModalCloseButton />

              <ModalBody>
                <ContentRatingInput
                  contentId={data.learningTrack._id}
                  contentType="Track"
                  ratingConfiguration={data.learningTrack.rating_configuration}
                />
              </ModalBody>
            </ModalContent>
          </Modal>

          <Celebration
            isOpen={celebrationIsOpen}
            setIsOpen={setCelebrationIsOpen}
            user={user.first_name}
            title={data.learningTrack.name}
          />

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.learningTrack.surveys[0].survey_id}
              contentId={data.learningTrack._id}
              contentType="track"
            />
          )}
        </>
      )}
    </>
  );
}

LearningTrack.defaultProps = {
  inJourney: false,
  journeyId: null,
  contentId: null,
  contextId: null,
};
