import { InputGroup, NumberInput, NumberInputField, Select, Stack, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReducer } from 'react';
import {
  ProductLicensingType,
  ProductSubscriptionDuration,
} from '@/client/services/api/graphql/gql/graphql';

export default function ProductSettingsForm() {
  const { getValues, setValue, control, watch } = useFormContext<any>();
  const { t } = useTranslation();
  const price = watch('price');
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const dropdownOptions = {
    licensing: [
      // {
      //   name: t('overviewProducts.licensing_permanent'),
      //   val: ProductLicensingType.Permanent,
      // },
      {
        name: t('overviewProducts.licensing_subscription'),
        val: ProductLicensingType.Subscription,
      },
    ],
    currency: [
      // TODO add more?
      {
        name: 'USD',
        val: 'USD',
      },
    ],
    subscriptionDuration: [
      {
        name: t('products.subscriptionDurationOptions.monthly'),
        val: ProductSubscriptionDuration.Monthly,
      },
      {
        name: t('products.subscriptionDurationOptions.yearly'),
        val: ProductSubscriptionDuration.Yearly,
      },
    ],
  };

  const selectSubscriptionDuration = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue('subscriptionDuration', event.target.value);
    forceUpdate();
  };

  const format = (val: string) => {
    if (!val) return '$';
    return '$' + val;
  };
  const parse = (val: string) => {
    if (!val || parseInt(val, 10) < 0) return '';
    return val.replace(/^\$/, '');
  };

  return (
    <Stack minHeight={1000} marginX={6}>
      <Text variant="createHeading">{t('authoring.tabs.settings')}</Text>
      <Text variant="createHelpText">{t('authoring.product.settingsSubHeading')}</Text>

      <Stack spacing={50} justifyContent="flex-start">
        <Stack>
          <Select
            backgroundColor="white"
            name="subscriptionDuration"
            flex="0.33"
            // @ts-ignore
            placeholder={t('authoring.product.subscriptionTermLength')}
            onChange={selectSubscriptionDuration}
            value={getValues('subscriptionDuration')}
          >
            {dropdownOptions.subscriptionDuration.map((opt) => (
              <option key={'option_' + opt.name} value={opt.val}>
                {opt.name}
              </option>
            ))}
          </Select>

          <InputGroup>
            <Controller
              name="price"
              control={control}
              rules={{
                required: { value: true, message: t('global.forms.field_required') },
              }}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  placeholder={t('authoring.product.pricePerSubscriptionTerm') as string}
                  variant="adminInput"
                  precision={2}
                  onChange={(valueString) => setValue('price', parse(valueString))}
                  value={format(price)}
                  width="full"
                >
                  <NumberInputField />
                </NumberInput>
              )}
            />
          </InputGroup>
        </Stack>
      </Stack>
    </Stack>
  );
}
