import {
  Article,
  Assessment,
  CalendarEvent,
  CalendarWeek,
  FileAlt,
  ImportedContent,
  LinkedIn,
  Scorm,
  Track,
  Udemy,
  Video,
} from '@/client/components/icons/ContinuIcons';

export const useRenderContentIcon = (type: string, vendor?: string, boxSize?: number) => {
  if (type === 'assessment') return <Assessment boxSize={boxSize} />;
  if (type === 'article') return <Article boxSize={boxSize} />;
  if (type === 'file') return <FileAlt boxSize={boxSize} />;
  if (type === 'video') return <Video boxSize={boxSize} />;
  if (type === 'scorm') return <Scorm boxSize={boxSize} />;
  if (type === 'track') return <Track boxSize={boxSize} />;
  if (type === 'workshop') return <CalendarEvent boxSize={boxSize} />;
  if (type === 'multi_session_workshop') return <CalendarWeek boxSize={boxSize} />;
  if (type === 'multi_session_workshop_session') return <CalendarWeek boxSize={boxSize} />;
  if (type === 'imported_content') {
    if (vendor === 'udemy') return <Udemy boxSize={boxSize} />;
    if (vendor === 'linkedin_learning') return <LinkedIn boxSize={boxSize} />;
    return <ImportedContent />;
  }
};
