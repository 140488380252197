/* eslint react/jsx-props-no-spreading: 0 */

import { Button, Stack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { Form, FormProducts } from '../../types/RegistrationForms';
import ProductCard from '@/client/components/data-display/cards/ProductCard';

interface SelectionProps {
  form: Form;
  value: string | undefined;
  selectProduct: (id: string) => void;
  nextPage: () => void;
}

export default function ProductSelection({ form, value, selectProduct, nextPage }: SelectionProps) {
  const { t } = useTranslation();
  const { formState } = useFormContext();

  return (
    <Stack spacing={8}>
      <Text fontSize="xl" fontWeight="600">
        {form.headline || t('ecommerce.checkout.selectProduct')}
      </Text>
      <Text>{form.description}</Text>

      {form.products?.map((product: FormProducts) => (
        <ProductCard
          product={product}
          onSelect={selectProduct}
          selected={!!value && value === product._id}
        />
      ))}

      <Button
        variant="adminPrimary"
        borderRadius={8}
        width="fit-content"
        alignSelf="flex-end"
        onClick={nextPage}
        isDisabled={!formState.isValid}
      >
        {t('global.form.labels_next')}
      </Button>
    </Stack>
  );
}
