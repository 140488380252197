import GroupsService from '@/client/services/api/admin/connect/GroupsService';
import SearchableDropdownInput from '@/client/components/input/SearchableDropdownInput';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function AdminUsersGroupSearch() {
  const { t } = useTranslation();

  const { data: groups } = useQuery({
    queryKey: ['admin-user-groups'],
    queryFn: () => GroupsService.getAllGroups(),
    select: (data) => {
      const formattedGroups = data.map((group) => ({ value: group._id, label: group.name }));

      return formattedGroups;
    },
  });

  return (
    <SearchableDropdownInput
      name="groups"
      isMultiple
      label="Groups"
      placeholder="Search Groups"
      options={groups || []}
      tooltipText={t('registrationForms.help.segment', { type: 'group' })}
    />
  );
}
