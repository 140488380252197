/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from 'react-hook-form';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Textarea,
} from '@chakra-ui/react';

import FormLabelWithTooltip from './FormLabelWithTooltip';
import { IoPricetagsOutline } from 'react-icons/io5';
import { useState } from 'react';

interface TextareaWithTooltipProps {
  name: string;
  label?: string;
  tooltipText?: string;
  isRequired?: boolean;
  placeholder?: string | null;
  insertOptions?: Record<string, string>;
}

export default function TextareaWithTooltip({
  name,
  label,
  tooltipText,
  isRequired,
  placeholder,
  insertOptions,
}: TextareaWithTooltipProps) {
  const { control, watch, setValue } = useFormContext();
  const [cursorPosition, setCursorPosition] = useState(0);

  const currentValue = watch(name);

  const onBlur = (e: any) => {
    setCursorPosition(e.target.selectionStart);
  };

  const insertVariable = (variable: string) =>
    setValue(
      name,
      currentValue.slice(0, cursorPosition) + variable + currentValue.slice(cursorPosition),
    );

  return (
    <Stack spacing={2}>
      {label && (
        <FormLabelWithTooltip
          label={label}
          isRequired={isRequired}
          tooltipText={tooltipText || ''}
        />
      )}

      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: isRequired || false, message: 'This field is required' },
        }}
        render={({ field }) => (
          <Textarea
            {...field}
            variant="adminInput"
            placeholder={placeholder || 'Type Something'}
            onBlur={onBlur}
          />
        )}
      />

      {insertOptions && (
        <Flex justifyContent="flex-end">
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              variant="ghost"
              width={6}
              aria-label="Predefined Insert Options"
              icon={<IoPricetagsOutline />}
            />
            <MenuList>
              {Object.entries(insertOptions).map(([k, v]) => (
                <MenuItem key={k} onClick={() => insertVariable(` {${k}}`)}>
                  {v}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      )}
    </Stack>
  );
}

TextareaWithTooltip.defaultProps = {
  label: null,
  tooltipText: null,
  isRequired: false,
  placeholder: null,
  insertOptions: null,
};
