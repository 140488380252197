import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import SingleColumnLayout from '@/client/components/layout/admin/SingleColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useTranslation } from 'react-i18next';

export default function Grades() {
  const { t } = useTranslation();
  const connect = useConnect('grade');

  const gradesPlural = t('admin.connect.grades.plural');
  const gradesSingular = t('admin.connect.grades.singular');

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: gradesSingular })}
        showSortSelector
        title={gradesPlural}
        setSearchTerm={connect.setSearchTerm}
        sortOrder={connect.sortOrder}
        setSortOrder={connect.setSortOrder}
        isSearching={connect.isSearching}
      />

      <SingleColumnLayout
        type="grade"
        placeholderType={gradesSingular}
        noSearchResultsText={t('admin.connect.noSearchResultsMain', { type: gradesPlural })}
        connect={connect}
      />

      <DeleteItemModal deleteItem={connect.deleteItem} />
    </>
  );
}
