import { Box, Flex } from '@chakra-ui/react';

import type { ReactElement } from 'react';

interface CreateLayoutProps {
  previewFullscreen: boolean;
  previewElement: ReactElement;
  formElement: ReactElement;
}

export default function CreateLayout({
  previewFullscreen,
  previewElement,
  formElement,
}: CreateLayoutProps) {
  return (
    <Flex
      marginTop={-2}
      maxHeight={window.innerHeight - 50}
      height={window.innerHeight - 50}
      overflowY="hidden"
    >
      <Box width={previewFullscreen ? 'full' : '40%'} backgroundColor="white" overflowY="auto">
        {previewElement}
      </Box>

      <Box
        width="60%"
        backgroundColor="brand.gold.10"
        overflowY="auto"
        display={previewFullscreen ? 'none' : 'unset'}
      >
        {formElement}
      </Box>
    </Flex>
  );
}
