import { learnApiClient } from './clients/learnApiClient';
import { Model } from 'survey-core';
import { getQuestionsByName, constructSubmissionResults } from '@/client/utils/surveys/handleSurveySubmission';



const getSurvey = async (surveyId: string, contentId: string, contentType: string, surveyLanguage: string, onComplete: () => void)=> {

  const response = await learnApiClient.get(`surveys/${surveyId}`);

  // Format Questions
  const questionsByName = getQuestionsByName(response.data.survey_json.pages, response.data.questions);

  // Initialize survey model
  const surveyJson = new Model(response.data.survey_json);
  surveyJson.locale = surveyLanguage;
  surveyJson.onComplete.add((sender: any, options: any) => {
    const formattedResults = constructSubmissionResults(sender.data, questionsByName);
    submitResults(surveyId, contentId, contentType, formattedResults);
    onComplete();
  });

  return surveyJson;
}

const submitResults = async (surveyId: string, contentId: string, contentType: string, results: any) => {
  const response = await learnApiClient.post(`surveys/results`, {
    survey_id: surveyId,
    content_id: contentId,
    content_type: contentType,
    results,
    date_completed: new Date()
  })
  return response;
}

const userHasCompletedContentSurvey = async (surveyId: string, contentId: string) => {
  const results = await learnApiClient.get(`surveys/${surveyId}/result/${contentId}`);
  const completed = !!results.data.date_completed
  return completed;
}

const SurveyService = {
  getSurvey,
  submitResults,
  userHasCompletedContentSurvey
};

export default SurveyService;