import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import TextareaWithTooltip from '../../forms/TextareaWithTooltip';

interface ProductGeneralDetailsFormProps {
  canAdvance: boolean;
}

export default function ProductGeneralDetailsForm({ canAdvance }: ProductGeneralDetailsFormProps) {
  const { t } = useTranslation();
  const { setCanAdvance } = useCreateStore();

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  return (
    <Stack spacing={6}>
      <FormLabelWithTooltip label={t('authoring.product.label.title')} isRequired />
      <CreateInput
        name="title"
        placeholder={t('authoring.product.titlePlaceholder')}
        variant="createTitle"
        isRequired
      />

        <TextareaWithTooltip
          name="description"
          placeholder={t('authoring.product.descriptionPlaceholder')}
          label={t('authoring.product.label.description') as string}
          isRequired
        />
    </Stack>
  );
}
