import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import ThreeColumnLayout from '@/client/components/layout/admin/ThreeColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useTranslation } from 'react-i18next';

export default function Locations() {
  const { t } = useTranslation();
  const connect = useConnect('location');

  const columnOneTitle = t('admin.connect.locations.columnOneTitle');
  const columnTwoTitle = t('admin.connect.locations.columnTwoTitle');
  const columnThreeTitle = t('admin.connect.locations.columnThreeTitle');
  const locationsPlural = t('admin.connect.locations.plural');
  const locationsSingular = t('admin.connect.locations.singular');
  const subLocationsPlural = t('admin.connect.locations.columnTwoTitlePlural');
  const subSubLocationsPlural = t('admin.connect.locations.columnThreeTitlePlural');

  const columnCopy = {
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    noSubItemsText: t('admin.connect.noSubItems', {
      subType: columnTwoTitle,
      mainType: columnOneTitle,
    }),
    noSubSubItemsText: t('admin.connect.noSubSubItems', {
      subSubType: columnThreeTitle,
      subType: columnTwoTitle,
    }),
    noResultsMain: t('admin.connect.noSearchResultsMain', { type: locationsPlural }),
    noResultsSub: t('admin.connect.noSearchResultsSub', {
      subType: subLocationsPlural,
      mainType: columnOneTitle,
    }),
    noResultsSubSub: t('admin.connect.noSearchResultsSubSub', {
      subSubType: subSubLocationsPlural,
      subType: columnTwoTitle,
    }),
  };

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: locationsSingular })}
        showSortSelector
        title={locationsPlural}
        setSearchTerm={connect.setSearchTerm}
        sortOrder={connect.sortOrder}
        setSortOrder={connect.setSortOrder}
        isSearching={connect.isSearching}
      />

      <ThreeColumnLayout
        isDragDisabled
        placeholderType={locationsSingular}
        type="location"
        columnCopy={columnCopy}
        connect={connect}
      />

      <DeleteItemModal
        subType={subLocationsPlural}
        subSubType={subSubLocationsPlural}
        deleteItem={connect.deleteItem}
      />
    </>
  );
}
