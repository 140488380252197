import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import {
  ArrowLeft,
  ArrowRight,
  Close,
  Expand,
  ZoomIn,
  ZoomOut,
} from '@/client/components/icons/ContinuIcons';
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useState } from 'react';

type PdfViewTypes = {
  source: string;
  recordPage: (page: number) => void;
  recoveredPage: number | undefined | null;
  enableRecommendations?: boolean;
  setRenderRecommendations: (render: boolean) => void;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PdfView({
  source,
  recordPage,
  recoveredPage,
  enableRecommendations,
  setRenderRecommendations,
}: PdfViewTypes) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    recordPage(pageNumber);

    if (enableRecommendations && pageNumber === numPages) {
      setRenderRecommendations(true);
    }
  }, [pageNumber]);

  const documentWidth = useBreakpointValue({
    base: 500,
    md: 850,
  });

  function onDocumentLoadSuccess({ numPages: numberOfPages }: { numPages: number }) {
    setNumPages(numberOfPages);

    setPageNumber(recoveredPage || 1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const zoomIn = () => setScale((prevScale) => prevScale + 0.1);

  const zoomOut = () => setScale((prevScale) => prevScale - 0.1);

  const onItemClick = ({ pageNumber: itemPageNumber }: any) => setPageNumber(itemPageNumber);

  const handleFullScreen = () => setIsFullScreen(!isFullScreen);

  return (
    <Box
      border="1px solid"
      borderColor="brand.gray.300"
      borderRadius="md"
      margin="0 auto"
      style={
        isFullScreen
          ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 9999,
              overflow: 'auto',
            }
          : {
              position: 'relative',
              maxWidth: 850,
            }
      }
    >
      <Flex
        backgroundColor="white"
        borderTopRadius="md"
        borderBottom="1px solid"
        borderBottomColor="brand.gray.300"
      >
        <Flex width="30%">
          <IconButton
            variant="ghost"
            aria-label="Fullscreen"
            icon={isFullScreen ? <Close /> : <Expand />}
            onClick={handleFullScreen}
          />
        </Flex>

        <Flex alignItems="center" width="40%" justifyContent="center">
          <HStack>
            <IconButton
              variant="ghost"
              aria-label="Previous Page"
              icon={<ArrowLeft />}
              isDisabled={pageNumber === 1}
              onClick={() => previousPage()}
            />

            <Text>
              {pageNumber} / {numPages}
            </Text>

            <IconButton
              variant="ghost"
              aria-label="Next Page"
              icon={<ArrowRight />}
              isDisabled={pageNumber === numPages}
              onClick={() => nextPage()}
            />
          </HStack>
        </Flex>

        <ButtonGroup width="30%" justifyContent="end">
          <IconButton variant="ghost" aria-label="Zoom In" icon={<ZoomIn />} onClick={zoomIn} />

          <IconButton variant="ghost" aria-label="Zoom Out" icon={<ZoomOut />} onClick={zoomOut} />
        </ButtonGroup>
      </Flex>

      <Box overflow="auto">
        <Document
          file={source}
          onLoadSuccess={(data) => onDocumentLoadSuccess(data)}
          onItemClick={onItemClick}
          loading={
            <Center marginY={8}>
              <Spinner />
            </Center>
          }
        >
          <Page
            pageNumber={pageNumber || 1}
            width={documentWidth}
            scale={scale}
            loading={
              <Center marginY={8}>
                <Spinner />
              </Center>
            }
          />
        </Document>
      </Box>
    </Box>
  );
}

PdfView.defaultProps = {
  enableRecommendations: false,
};
